import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Theme } from 'mewe/constants';
import AccountApi from 'mewe/api/account-api';
import { processCloudStorageData } from 'mewe/utils/miscellaneous-utils';

export default class AppMycloud extends Component {
  @service account;
  @service dynamicDialogs;

  @tracked activeTopTab = 0;
  @tracked activeBottomTab = 0;
  @tracked albumStreamActive = false;
  @tracked albumStreamApi = null;
  @tracked groupStreamActive = false;
  @tracked groupStreamApi = null;
  @tracked groupDocumentsActive = false;
  @tracked groupDocumentsApi = null;
  @tracked currentGroupId = Theme.MYCLOUD;
  @tracked currentFolder = null;
  @tracked cloudStorageInfo;

  constructor() {
    super(...arguments);

    // this is fetched here and passed down because this data presentation component
    // is used in two places depending on the screen size - this way we avoid two separate API calls.
    // if we ever need fetch this data in the StorageUsage component we should abstract data fetching
    this.fetchAndSetUsedSpaceDetails();

    if (this.args.model.hash) {
      this.openFromHash(this.args.model.hash);
    }
  }

  get showSorting() {
    const isPhotosNonGroups = this.activeTopTab === 0 && this.activeBottomTab !== 3;
    const isFilesNonGroups = this.activeTopTab === 1 && this.activeBottomTab === 0;
    const isAudioStream = this.activeTopTab === 2;
    return !this.albumStreamActive && (isPhotosNonGroups || isFilesNonGroups || isAudioStream);
  }

  get showAlbumsTab() {
    return this.activeBottomTab === 1 && !this.albumStreamActive;
  }

  get showGroupsPhotosTab() {
    return this.activeBottomTab === 3 && !this.groupStreamActive;
  }

  get showGroupDocumentsTab() {
    return this.activeBottomTab === 1 && !this.groupDocumentsActive;
  }

  get showSecondaryNav() {
    return this.activeTopTab === 0 || this.activeTopTab === 1;
  }

  openFromHash(hash) {
    if (hash.indexOf('#newgroup') === 0) {
      this.dynamicDialogs.openDialog('group-create');
    } else if (hash.indexOf('#upgrade') === 0) {
      this.dynamicDialogs.openDialog('store/store-item-storage-dialog');
    } else if (hash.indexOf('#mycontent') === 0) {
      let uuidIndex = hash.lastIndexOf('=');

      if (uuidIndex !== -1) {
        this.dynamicDialogs.openDialog('exported-content-dialog', {
          uuid: hash.slice(uuidIndex + 1, hash.length),
        });
      } else {
        this.dynamicDialogs.openDialog('download-content-dialog');
      }
    }
  }

  fetchAndSetUsedSpaceDetails() {
    AccountApi.getSpaceUsedStatus(false).then((data) => {
      if (this.isDestroyed || this.isDestroying) return;
      this.cloudStorageInfo = processCloudStorageData(data);
    });
  }

  @action
  openAlbumStream(album) {
    this.albumStreamApi = album;
    this.albumStreamActive = true;
  }

  @action
  openGroupStream(group) {
    this.groupStreamApi = group;
    this.groupStreamActive = true;
  }

  @action
  openGroupDocuments(group) {
    this.groupDocumentsApi = group;
    this.groupDocumentsActive = true;
  }

  @action
  resetTabs() {
    if (this.albumStreamActive) {
      this.albumStreamApi = null;
      this.albumStreamActive = false;
    }

    if (this.groupStreamActive) {
      this.groupStreamApi = null;
      this.groupStreamActive = false;
    }

    if (this.groupDocumentsActive) {
      this.groupDocumentsApi = null;
      this.groupDocumentsActive = false;
    }
  }

  @action
  switchTopTab(tabIndex) {
    if (tabIndex !== this.activeTopTab) {
      this.resetTabs();

      this.activeTopTab = tabIndex;
      this.activeBottomTab = 0; // set default bottom tab on top tab change
    }
  }

  @action
  switchBottomTab(tabIndex) {
    let didReset;

    if (this.albumStreamActive || this.groupStreamActive || this.groupDocumentsActive) {
      this.resetTabs();
      didReset = true;
    }

    if (this.activeBottomTab === tabIndex) {
      return false;
    }

    if (!didReset) {
      this.resetTabs();
    }

    this.activeBottomTab = tabIndex;
  }

  @action
  setCurrentFolder(folder) {
    this.currentFolder = folder;
  }
}
