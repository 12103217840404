import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { reads, alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { each } from 'lodash';
import { inject as service } from '@ember/service';

import GroupApi from 'mewe/api/group-api';
import EventsApi from 'mewe/api/events-api';
import PagesApi from 'mewe/api/pages-api';
import dispatcher from 'mewe/dispatcher';
import { Theme } from 'mewe/constants';

export default class MwMutualContacts extends Component {
  @service chat;

  @tracked contacts = [];

  @tracked totalCount = 0;

  @reads('args.rootEvent') rootEvent;

  @reads('args.group') group;

  @reads('args.page') page;

  @alias('args.group.id') groupId;

  @alias('args.rootEvent.id') eventId;

  @alias('args.page.id') pageId;

  maxContacts = 4;

  constructor() {
    super(...arguments);

    this.loadContacts();

    addObserver(this, 'scopeId', this, this.loadContacts);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    removeObserver(this, 'scopeId', this, this.loadContacts);
  }

  @computed('args.theme', 'groupId', 'eventId', 'pageId')
  get scopeId() {
    switch (this.args.theme) {
      case Theme.GROUP:
        return this.groupId;
      case Theme.EVENT:
        return this.eventId;
      case Theme.PAGE:
        return this.pageId;
    }
  }

  @computed('contacts.length')
  get followersText() {
    if (this.contacts.length === 1) {
      switch (this.args.theme) {
        case Theme.GROUP:
          return __(`<strong>{userName}</strong> is member.`, { userName: this.contacts[0].name });
        case Theme.EVENT:
          return __(`<strong>{userName}</strong> is attending.`, { userName: this.contacts[0].name });
        case Theme.PAGE:
          return __(`<strong>{userName}</strong> is following.`, { userName: this.contacts[0].name });
      }
    } else if (this.contacts.length === 2) {
      switch (this.args.theme) {
        case Theme.GROUP:
          return __(`<strong>{userName}</strong> and <strong>{userName2}</strong> are members.`, {
            userName: this.contacts[0].name,
            userName2: this.contacts[1].name,
          });
        case Theme.EVENT:
          return __(`<strong>{userName}</strong> and <strong>{userName2}</strong> are attending.`, {
            userName: this.contacts[0].name,
            userName2: this.contacts[1].name,
          });
        case Theme.PAGE:
          return __(`<strong>{userName}</strong> and <strong>{userName2}</strong> are following.`, {
            userName: this.contacts[0].name,
            userName2: this.contacts[1].name,
          });
      }
    } else if (this.contacts.length > 2) {
      switch (this.args.theme) {
        case Theme.GROUP:
          return __(`<strong>{userName}</strong> and {count} others you follow are members.`, {
            userName: this.contacts[0].name,
            count: this.totalCount - 1,
          });
        case Theme.EVENT:
          return __(`<strong>{userName}</strong> and {count} others you follow are attending.`, {
            userName: this.contacts[0].name,
            count: this.totalCount - 1,
          });
        case Theme.PAGE:
          return __(`<strong>{userName}</strong> and {count} others you follow are following.`, {
            userName: this.contacts[0].name,
            count: this.totalCount - 1,
          });
      }
    }
  }

  @computed('rootEvent.eventInviteBtnText')
  get inviteBtnText() {
    switch (this.args.theme) {
      case Theme.GROUP:
        return __('Invite members');
      case Theme.EVENT:
        return this.rootEvent?.eventInviteBtnText;
      case Theme.PAGE:
        return __('Invite followers');
    }
  }

  get showInviteBtn() {
    return this.page?.canInvite || this.group?.canInvite || this.rootEvent?.canInvite;
  }

  get canOpenChat() {
    return (this.group && !this.group.isUniversal && this.group.chatMode === 'on') || this.rootEvent?.chatMode === 'on';
  }

  loadContacts() {
    if (!this.scopeId) {
      return;
    }
    if (this.loadedForId === this.scopeId) {
      return;
    }

    this.contacts = [];
    this.loadedForId = this.scopeId;

    switch (this.args.theme) {
      case Theme.GROUP:
        GroupApi.myContactsInGroups({ groupIds: this.scopeId }).then((data) => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          const groupContacts = data.groups?.find((g) => g.groupId === this.scopeId);
          let contacts = [];

          each(groupContacts?.users, (id) => {
            const user = data.users.find((u) => u.id === id);
            if (user) {
              contacts.push(user);
            }
          });

          this.contacts = contacts;
          this.totalCount = contacts.length + (groupContacts?.others || 0);
        });
        break;

      case Theme.EVENT:
        EventsApi.getContactsInEvent(this.scopeId, { limit: 4 }).then((data) => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          this.totalCount = data.totalUsers;
          this.contacts = data.users;
        });
        break;

      case Theme.PAGE:
        PagesApi.getFollowingContacts(this.scopeId, { limit: 100 }).then((data) => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          // currently BE is not returning totalUsers so in fact we ask for 30 results in the request
          // to lower the chance of showing wrong info about contacts following page
          // BE ticket for that: SG-34900, this can be cleaned when BE is ready
          this.totalCount = data.totalUsers || data.followers?.length;
          this.contacts = data.followers;
        });
        break;
    }
  }

  groupInviteDialog() {
    dispatcher.dispatch('group-member', 'invite', this.group);
  }

  eventInviteDialog() {
    dispatcher.dispatch('events', 'openInvitationDialog', this.rootEvent);
  }

  pageInviteDialog() {
    dispatcher.dispatch('pages', 'openInvitationDialog', this.page);
  }

  @action
  invite() {
    switch (this.args.theme) {
      case Theme.GROUP:
        this.groupInviteDialog();
        break;
      case Theme.EVENT:
        this.eventInviteDialog();
        break;
      case Theme.PAGE:
        this.pageInviteDialog();
        break;
    }
  }

  @action
  openChat() {
    this.chat.openThreadById(this.group?.id || this.rootEvent?.id);
  }
}
