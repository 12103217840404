import Component from '@glimmer/component';
import { each } from 'lodash';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';

import { isFeatureHintValid, removeFeatureHint } from 'mewe/utils/feature-hint';

/**
 * USAGE
 * <BasicUi::MwSort @options={{someOptions}}/>
 *
 * someOptions congi object with subOptions containing options values
 *
 * action on select is called in parentView
 * and subOption that was selected is passed to this action
 *  {
      subOptions: [
        {
          label: __('Newest'),
          action: 'oderByNewest',
          selected: true
        },
        {
          label: __('Oldest'),
          action: 'oderByOldest',
          selected: false
        } .. etc
      ]
    }
 *
 *
 */


export default class MwSort extends Component {
  @tracked sortDropdownOpened = false;
  @tracked showTooltip = false;
  @tracked parent;

  constructor() {
    super(...arguments);

    if (this.args.withTooltip) {
      isFeatureHintValid('group-sort-members-tooltip').then((data) => {
        if (this.isDestroyed || this.isDestroying) return;
        this.showTooltip = data?.timesViewed === 0;
      });
    }
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @computed('args.options.subOptions.@each.selected')
  get selectedOption() {
    return this.args.options?.subOptions?.find(o => o.selected);
  }

  @computed('args.options.subOptions.@each.selected')
  get isDefaultSelected() {
    const selected = this.selectedOption;
    return selected ? selected.default : true;
  }

  @action
  toggleSortDropdown() {
    if (this.showTooltip) return;

    this.parent = this.element.querySelector('.dropdown-icon');

    this.sortDropdownOpened = !this.sortDropdownOpened;
  }

  @action
  closeSortDropdown() {
    this.sortDropdownOpened = false;
  }

  @action
  dismissTooltip() {
    removeFeatureHint('group-sort-members-tooltip');
    this.showTooltip = false;
  }

  @action
  subOptionCheck(subOption) {
    each(this.args.options.subOptions, (option) => {
      set(option, 'selected', false);
    });

    set(subOption, 'selected', true);

    this.args[subOption.action](subOption);

    this.closeSortDropdown();
    this.args.onClose?.();
  }
}