import Component from '@glimmer/component';
import { action } from '@ember/object';

import MiscUtils from 'mewe/utils/miscellaneous-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class MwCopyLink extends Component {
  get post() {
    return this.args.post;
  }

  get showIcon() {
    return this.args.showIcon ?? true;
  }

  @action
  click() {
    const groupId = this.post.group.id;
    let link = `/post/show/${this.post.id}`;

    if (this.post.pageId) {
      link += `?ppId=${this.post.page.publicLinkId}`;
    } else if (this.post.event2 && !this.post.eventInfo) {
      link += `?eventId=${this.post.event2.id}`;
    } else if (groupId && groupId !== 'contacts' && !this.post.event2) {
      link += `?groupId=${groupId}`;
    }

    MiscUtils.copyToClipboard(EnvironmentUtils.getHost() + link);
    FunctionalUtils.info(__('Copied to your clipboard'));
  }
}
