import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwAttachmentButton extends Component {
  @service pickers;
  element;

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  openAttachmentDropdown() {
    this.args.setAttachmentDropdown(true);
  }

  @action
  openGiphyPopup() {
    this.args.openGiphyPopup(this.element);
    this.args.setAttachmentDropdown(false);
  }

  @action
  browseFiles() {
    this.args.browseFiles();
  }

  @action
  browseVideos() {
    this.args.browseVideos();
  }

  @action
  openPmPostbox() {
    this.args.openPrivacyMailPostbox('mini-chat');
    this.args.setAttachmentDropdown(false);
  }

  @action
  openAudioRecording() {
    this.args.openAudioRecording();
    this.args.setAttachmentDropdown(false);
  }

  @action
  openStickerPicker() {
    this.pickers.openStickerPicker(this);
  }

  setSticker(sticker) {
    this.args.setSticker(sticker);
  }

  @action
  closeDropdown() {
    this.args.setAttachmentDropdown(false);
  }
}
