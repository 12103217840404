// TODO rework this file so it will be independent from session
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CurrentUserStore from 'mewe/stores/current-user-store';
import Session from 'mewe/shared/session';
import PS from 'mewe/utils/pubsub';
import config from 'mewe/config';
import Verbose from 'mewe/utils/verbose';
import cookie from 'mewe/shared/cookie';
import { createDeferred } from 'mewe/shared/utils';
import dispatcher from 'mewe/dispatcher';
import { toUrlParams } from 'mewe/shared/utils';

const VerboseManager = Verbose({ prefix: '[WS]' });
const verbose = VerboseManager.log;

export default class WebsocketService extends Service {
  @tracked isAlive = false;
  @tracked retriesCount = 0;
  @tracked deferred = null;

  websocket = null;
  reopenTimeout = null;
  reconnectDelay = 5000; // ms
  connectionInitiated = false;
  lastPingTime = null;
  connectionCheckInterval = 15000; // it is the same on server, don't change it unless it changed on server!!! (MW)
  subscribedToTokenRefresh = false;

  types = {
    'post-new-model': 'post.add',
    'post-remove': 'post.remove',
    'post-edit': 'post.edit',
    'post-emojis-add': 'post.emoji.add',
    'post-emojis-removed': 'post.emoji.remove',
    'comment-new': 'comment.add',
    'comment-remove': 'comment.remove',
    'comment-edit': 'comment.edit',
    'comment-emojis-add': 'comment.emoji.add',
    'comment-emojis-removed': 'comment.emoji.remove',
    'chat-message-emoji-added': 'chat.message.emoji.add',
    'chat-message-emoji-removed': 'chat.message.emoji.remove',
    'doc-edit': 'doc.edit',

    newChatMessage: 'chat.message.add',
    'chat-newMessage': 'chat.message.add',
    GroupChatMessage: 'chat.message.add',
    EventChatMessage: 'chat.message.add',

    DelGroupChatMessage: 'groupchat.message.remove',
    DelEventChatMessage: 'eventchat.message.remove',
    groupChatModeChanged: 'groupchat.mode.changed',
    DelAllChatMessages: 'chat.remove.all.messages',
    removeThreadParticipant: 'chat.participant.remove',
    'chat-delMessage': 'chat.message.del',
    'chat-editMessage': 'chat.message.edit',
    isTypingGroupChatMessage: 'groupchat.typing',
    isTypingChatMessage: 'chat.typing',
    'event-reminder': 'event.reminder',
    'new-notification': 'notification.add',
    'new-desktop-notification': 'desktop.notification.add',
    'contact-import-ready': 'contacts.import.ready',
    'contact-import-failed': 'contacts.import.failed',
    'validation-via-import-failed': 'validation.import.failed',
    'remove-from-album': 'remove.from.album',
    'add-to-album': 'add.to.album',
    'new-group-application': 'group.application.new',
    'removed-group-application': 'group.application.remove',
    'group-member-remove': 'group.member.remove',
    'new-group': 'group.new',

    'call-started': 'call.started',
    'call-declined': 'call.declined',
    'call-joined': 'call.joined',

    'poll-vote-add': 'poll.vote.add',
    'poll-vote-remove': 'poll.vote.remove',
    'poll-vote-change': 'poll.vote.change',

    newPageNotification: 'page.notification.new',
    pageUnpublished: 'page.unpublished',
    itemPurchased: 'item.purchased',
    'store-permissions-updated': 'store.permissions.updated',

    'post-link-updated': 'post.link.updated',
    'comment-link-updated': 'comment.link.updated',
    'chat-link-updated': 'chat.link.updated',
    'new-story': 'new.story',
    'msa-created': 'msa.created',
    'msa-handle-change-result': 'msa.handle.change.result',
    'transcoding-job-done': 'transcoding.job.done',

    // Not a proper ws message, but uses pub/sub:
    // - contact-invitees.new
    // - documents.reload
    // - photoAlbums.reload
    // - photoStream.update
    // - photoTags.reload
    // - contact.remove       // TODO - btw, why it's not a ws message?
    // - close.smart.search
  };

  constructor() {
    super(...arguments);

    verbose('init');

    try {
      if (localStorage.getItem('meweVerbose')) {
        // TODO testing purposes
        window.meweWS = this;
      }
    } catch (e) {}

    this.deferred = createDeferred();
  }

  open() {
    verbose('opening');

    if (config.testing) return;

    if (this.isAlive) {
      verbose('connection already established');

      this.deferred.resolve();
      return;
    }

    if (this.connectionInitiated) {
      return;
    }

    this.connectionInitiated = true;

    if (!window.WebSocket) return;

    const callback = () => {
      const userId = CurrentUserStore.getState().get('id');
      if (!userId) {
        verbose('userId is missing');
        return;
      }

      const wsParams = toUrlParams({ userId: userId });
      let wsUri;

      if (config.environment == 'local') {
        wsUri = `ws://localhost:${window.location.port}/ws/indexWS?${wsParams}`;
      } else {
        wsUri = `${config.websocketsHost}/indexWS?${wsParams}`;
      }

      verbose(`connecting to: ${wsUri}`);

      this.websocket = new WebSocket(wsUri);
      this.websocket.onopen = (evt) => {
        this.onOpen(evt);
      };
      this.websocket.onclose = (evt) => {
        this.onClose(evt);
      };
      this.websocket.onmessage = (evt) => {
        this.onMessage(evt);
      };
      this.websocket.onerror = (evt) => {
        this.onError(evt);
      };
    };

    CurrentUserStore.getState().deferred.promise.then(() => {
      Session.isAuthenticated().then(({ isAuthenticated, needsTos }) => {
        if (needsTos) dispatcher.dispatch('app', 'showTosDialog');
        if (isAuthenticated) callback.call(this);
      });
    });
  }

  restart() {
    verbose('restart');
    verbose('isAlive: ' + this.isAlive);

    if (this.websocket) {
      if (this.isAlive) {
        try {
          this.websocket.close();
        } catch (err) {
          // e.g. connection was already closed
          verbose(`CLOSE ERROR on restart:`);
          verbose(err);
          this.onClose();
        }
      } else {
        // connection is already closed, calling close is doing nothing, we need to invoke onClose explicitly
        this.onClose();
      }
    } else {
      this.open();
    }
  }

  onOpen() {
    verbose('onOpen');

    this.isAlive = true;
    this.retriesCount = 0;

    this.setLastPingTime();

    PS.Pub('websocket.connection.success');

    verbose(`connected to server: ${config.websocketsHost}`);

    if (!this.subscribedToTokenRefresh) {
      this.subscribedToTokenRefresh = true;
      PS.Sub('tokens.refresh', () => {
        verbose('tokens refreshed, restart WS');
        this.restart();
      });
    }

    this.deferred.resolve();

    this.scheduleConnectivityCheck();
  }

  formattedNumber(num) {
    return ('0' + num).slice(-2);
  }

  setLastPingTime() {
    const date = new Date();

    this.lastPingTime = date.getTime();

    if (VerboseManager.isEnabled()) {
      var str =
        date.getFullYear() +
        '-' +
        this.formattedNumber(date.getMonth() + 1) +
        '-' +
        this.formattedNumber(date.getDate()) +
        ' ' +
        this.formattedNumber(date.getHours()) +
        ':' +
        this.formattedNumber(date.getMinutes()) +
        ':' +
        this.formattedNumber(date.getSeconds());
      verbose(`setting last ping time to ${str}`);
    }
  }

  onClose(e) {
    verbose('onClose');

    this.isAlive = false;
    this.connectionInitiated = false;

    clearTimeout(this.connectionTimeout);
    delete this.connectionTimeout;
    verbose('disconnected, bye...');

    const maybeClosedByPageRefresh = e && e.wasClean && e.code === 1001;

    this.scheduleReconnect(e, maybeClosedByPageRefresh);
  }

  _handleMessage(msgJson) {
    if (msgJson) {
      PS.Pub(this.types[msgJson.msgType], msgJson.data);

      if (msgJson.msgNo) {
        this.acknowledgeMsg(msgJson.msgNo);
      } else if (msgJson.msgType !== 'chat-isOnline') {
        // ack also pongs to have keepAlive
        this.doSend('pong');
      }
    }
  }

  onMessage(evt) {
    verbose(`message: ${evt.data}`);

    if (evt && evt.data) {
      let msgJson;

      try {
        msgJson = JSON.parse(evt.data);
      } catch (e) {
        verbose(`ERROR: ${e}`);
      }

      //condition is reverted just in case we get NaN, then cdn-exp will be refreshed too
      if (!(+cookie.get('cdn-exp') > Date.now())) {
        Session.refreshAuthentication().then(() => {
          this._handleMessage(msgJson);
        });
      } else {
        this._handleMessage(msgJson);
      }

      this.setLastPingTime();
    }
  }

  onError(evt) {
    verbose(`ERROR: ${evt.data}`);
    PS.Pub('websocket.connection.error');
    this.isAlive = false;
    this.scheduleReconnect(evt);
  }

  // now implemented only for testing
  doSend(message) {
    let json = JSON.stringify({
      message: message,
    });

    verbose(`sent: ${message}`);

    try {
      this.websocket.send(json);
    } catch (err) {
      // e.g. connection was just closed but tried to pong
      verbose(`SEND ERROR:`);
      verbose(err);
    }
  }

  scheduleReconnect(reason, maybeClosedByPageRefresh) {
    verbose('scheduleReconnect');

    reason = reason || {};

    this.retriesCount++;

    /*
    if (this.retriesCount === 3) {
      throw new Error(`Could not connecto to websocket. Code: ${reason.code || "Unknown"}; Reason: ${reason.reason || "Unknown"}`);
    }
    */

    if (!this.isAlive) {
      var refreshAfter = this.reopenTimeout || maybeClosedByPageRefresh ? this.reconnectDelay : 0; // 5 sec or 0 if first time
      clearTimeout(this.reopenTimeout);

      delete this.reopenTimeout;

      this.reopenTimeout = setTimeout(() => {
        this.open();
      }, refreshAfter);
    }
  }

  acknowledgeMsg(msgNo) {
    if (msgNo) {
      const json = JSON.stringify({
        ack: msgNo,
      });

      try {
        this.websocket.send(json);
      } catch (err) {
        verbose(`acknowledgeMsg SEND ERROR:`);
        verbose(err);
      }
    }
  }

  // websockets protocol cannot detect if connection is alive eg. in case when switch ip, we use ping/pong mechanism recommended for such situations
  scheduleConnectivityCheck() {
    verbose('scheduleConnectivityCheck');

    clearTimeout(this.connectionTimeout);
    delete this.connectionTimeout;

    this.connectionTimeout = setTimeout(() => {
      var date = new Date().getTime(),
        timeFromLastPingMs = date - this.lastPingTime;

      verbose(`last ping/ WS msg was ${timeFromLastPingMs}ms ago`);

      if (timeFromLastPingMs > this.connectionCheckInterval + 1000) {
        this.isAlive = false;

        try {
          this.websocket.close(); // it's closed on server side, close it also on our side
        } catch (err) {
          // e.g. connection was already closed
          this.onClose(err);
        }

        this.restart();
      }

      this.scheduleConnectivityCheck();
    }, this.connectionCheckInterval);
  }
}
