import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import MyCloudApi from 'mewe/api/mycloud-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class AudioOptionsComponent extends PopupOpener {
  layout = layout;

  @service dynamicDialogs;

  @action
  deleteAudio() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Delete file'),
      message: __(
        'Deleting this file will permanently remove it from My Cloud.'
      ),
      cancelButtonText: __('Cancel'),
      okButtonText: __('Delete'),
      onConfirm: () => {
        MyCloudApi.deleteAudio([this.args.audio.id])
          .then(() => {
            this.args.removeAudio(this.args.audio);
            FunctionalUtils.info(__('File deleted'));
          })
          .catch(() => {
            FunctionalUtils.showDefaultErrorMessage();
          });
        this.args.onClose();
      }
    });
  }

  @action
  renameAudio() {
    this.dynamicDialogs.openDialog('rename-audio-file-dialog', {
      audio: this.args.audio,
      confirmCallback: (newName) => {
        MyCloudApi.renameAudio(this.args.audio.id, { newName })
          .then(() => {
            this.args.updateName(newName);
            this.args.onClose();
          })
          .catch(() => {
            FunctionalUtils.showDefaultErrorMessage();
          });
      }
    });
  }
}

