// displaying 1 floating point for single digit numbers and if it's not '0'
// examples: 999, 1k, 1.2k, 12k, 122k, 3M, 3.3M, 33M
export const formatBigNumber = (num) => {
  if (typeof num === 'string') {
    num = parseInt(num, 10);
  }

  if (isNaN(num) || typeof num !== 'number' || typeof num !== 'number') return '';

  let scales = [
    { suffix: 'M', value: 1000000 },
    { suffix: 'K', value: 1000 },
  ];

  num = parseInt(num, 10);

  scales.forEach((scale) => {
    if (num === scale.value) {
      num = num / scale.value + scale.suffix;
    } else if (num > scale.value) {
      num = num / scale.value + '';

      if (num.indexOf('.') !== -1) {
        num = num.slice(0, num.indexOf('.') + 2);
      }

      let numParts = num.split('.');

      if (!numParts[1] || numParts[1] === '0' || num.length > 3) {
        num = numParts[0] + scale.suffix;
      } else {
        num = num + scale.suffix;
      }
    }
  });

  return num + ''; // make it string for consistency in case of numbers < 1k
};

export default {
  // TODO check if not delivered with some included library
  mod(n, m) {
    return ((n % m) + m) % m;
  },

  generateId: function () {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    //return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    return S4() + S4() + S4() + S4();
  },

  getDocumentSizeText(bytes) {
    const KB = 1024;
    const MB = 1048576;

    if (bytes <= KB) return `${bytes} B`;
    else if (bytes > KB && bytes < MB) return `${(bytes / KB).toFixed(2)} KB`;
    else if (bytes >= MB) return `${(bytes / MB).toFixed(2)} MB`;
  },

  bytesToGBText(bytes) {
    var GB = (bytes / 1024 / 1024 / 1024).toString(),
      decPos = GB.indexOf('.');

    if (decPos >= 0) {
      GB = GB.slice(0, decPos + 2);
    }

    return GB;
  },

  bytesToMBText(bytes) {
    var MB = (bytes / 1024 / 1024).toString(),
      decPos = MB.indexOf('.');

    if (decPos >= 0) {
      MB = MB.slice(0, decPos + 3);
    }

    return MB;
  },
};
