import config from 'mewe/config';
import * as Sentry from '@sentry/ember';
import Verbose from 'mewe/utils/verbose';

const verbose = Verbose({ prefix: '[Sentry]' });
const log = verbose.log;

export function startSentry() {
  log(`Sentry Init DSN: ${config.sentryDSN}`);

  Sentry.init({
    dsn: config.sentryDSN,
    release: config.buildNumber,
    environment: config.environment,
    integrations: [new Sentry.Replay()],
    tracesSampleRate: config.tracesSampleRate,

    beforeSend(event, hint) {
      let error = hint.originalException;

      if (error && error.name === 'TransitionAborted') {
        return null;
      }

      log('Sentry report', error);

      return event;
    },
  });
}
