import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';

export default class BaseRoute extends Route {
  constructor() {
    super(...arguments);

    if (window.location.hash?.length >= 2) {
      this.set('hash', window.location.hash);
    }

    // remove app store banner on top when mobile uses this param in url
    // used e.g. when mobile web page is opened in app's webview
    if (~window.location.href.indexOf('?mobileApp=true')) {
      document.querySelector("[name='apple-itunes-app']")?.remove();
    }
  }

  cleanRouteHash() {
    if (this.hash) {
      // clean hash in route
      this.set('hash', null);

      // clean hash in url
      if (window.location.hash) {
        history.pushState('', document.title, window.location.pathname + window.location.search);
      }
    }
  }

  activate() {
    if (this.modelFor('app')) {
      const appRoute = getOwner(this).lookup('route:app');
      if (appRoute) appRoute.set('theme', this.theme);

      this.modelFor('app').setProperties({
        theme: this.theme,
        locationHash: window.location.hash,
      });
    }

    // if there will be a case when transition shouldn't scroll to the top
    // then we should try to solve it with some flag in the route indicating that
    // this scroll should be skipped. Otherwise we would need to add this scroll
    // to all the routes which would be less maintainable and reliable.
    window.scrollTo(0, 0);
  }
}
