import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MwChatCallDropdown extends Component {

  get rightTranslateMenu() {
    return this.args.rightTranslateMenu ??'calc(-10% - 18px)';
  }

  @action
  openChatCallDropdown() {
    this.args.toggleCallDropdown();
  }

  @action
  startCall(videoEnabled) {
    this.args.startCall?.(videoEnabled);
    this.args.toggleCallDropdown();
  }

  @action
  setupStickyHeight(element) {
    if (this.args.stickyHeightUpdate) {
      this.args.stickyHeightUpdate(true, element);
    }
  }

  @action
  destroyStickyHeight(element) {
    if (this.args.stickyHeightUpdate) {
      this.args.stickyHeightUpdate(false, element);
    }
  }
}
