import Component from '@glimmer/component';
import { reads } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { ds } from 'mewe/stores/ds';
import { tracked } from '@glimmer/tracking';

import { TellerType } from 'mewe/constants';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import MiscUtils from 'mewe/utils/miscellaneous-utils';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
export default class MwGroupMain extends Component {
  @service dynamicDialogs;
  @service tasks;

  @reads('args.rootEvent') rootEvent;
  @reads('args.group') group;
  @reads('args.page') page;
  @reads('args.isOpenPage') isOpenPage;

  @tracked entries = this.args.entries;
  @tracked isAvatarClicked = false;
  @tracked isAvatarDropdownOpen = false;
  @tracked isStoryCreated = false;

  get pagePublicUrl() {
    return this.page.publicUrl.replace('/p/', '/');
  }
  get downloadQrCode() {
    return this.page.publicUrl + '/qr';
  }

  getWindowFeatures() {
    return (
      'status=no,height=330,width=700,resizable=yes,left=' +
      (window.screen.width / 2 - 300) +
      ',top=' +
      (window.screen.height / 2 - 165)
    );
  }

  get pagePublicUrl() {
    return this.page.publicUrl.replace('/p/', '/');
  }

  @computed('rootEvent.name', 'group.name', 'page.name')
  get communityName() {
    if (this.rootEvent) {
      return this.rootEvent.name;
    } else if (this.group) {
      return this.group.name;
    } else if (this.page) {
      return this.page.name;
    }
  }

  @computed('rootEvent.name', 'group.name', 'page.name')
  get communityCountText() {
    if (this.rootEvent) {
      return false;
    } else if (this.group) {
      if (this.group.membersCount === 0) return '';

      return __('{count} Members', { count: this.group.membersCount });
    } else if (this.page) {
      return __('{count} Followers', { count: this.page.followers });
    }
  }

  @computed('rootEvent.description', 'group.descriptionPlain', 'page.description')
  get descriptionPlain() {
    if (this.rootEvent) {
      return this.rootEvent.description;
    } else if (this.group) {
      return this.group.descriptionPlain;
    } else if (this.page) {
      return this.page.description;
    }
  }

  get avatarClass() {
    return this.hasNewEntries ? 'has-unseen' : 'has-seen';
  }

  get hasNewEntries() {
    if (this.isStoryCreated) {
      return true;
    }
    if (!this.entries?.items?.length) return;
    return this.entries.items.filterBy('isNew', true).length > 0;
  }

  get showAddStoryButton() {
    return this.page && (this.page.isOwner || this.page.isAdmin) && !this.isOpenPage && this.page.published;
  }

  @action
  pageUpdated() {
    this.isStoryCreated = false;
    this.entries = this.args.entries;
  }

  @action
  avatarClick() {
    if (this.isOpenPage && (!this.page.isNsfw || (this.isAvatarClicked && this.page.isNsfw))) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.page.isNsfw && this.isAvatarClicked === false) {
      this.isAvatarClicked = true;
      return;
    }

    // if there is any story then open dropdown, otherwise open avatar in MD
    if (this.entries?.items?.length || this.isStoryCreated) {
      this.isAvatarDropdownOpen = true;
    } else {
      this.dynamicDialogs.openDialog('media-dialog', {
        photoUrl: this.page?.avatar,
        mediaType: 'photoUrl',
        allowMultipleInstances: true,
      });
    }
  }

  @action
  openProfileStories() {
    next(() => {
      this.isAvatarDropdownOpen = false;
      this.isStoryCreated = false;
    });
    // teller might exist in store, if yes then will be updated
    const teller = ds.tellers.items.find((el) => el.id === this.page.id);

    const params = {
      tellerId: this.page.id,
      tellerName: this.page.name,
      tellerType: TellerType.PAGE,
      avatarHref: this.page.avatar,
      pageUrlId: this.page.publicLinkId,
      isPageAdminOwner: this.page.isOwnerAdmin,
      allEntriesSeen: () => {
        if (teller) teller.set('hasNewStories', false);
      },
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  openProfilePhoto() {
    next(() => {
      this.isAvatarDropdownOpen = false;
    });
    this.dynamicDialogs.openDialog('media-dialog', {
      photoUrl: this.page?.avatar,
      mediaType: 'photoUrl',
      allowMultipleInstances: true,
    });
  }

  @action
  closeAvatarDropdown() {
    this.isAvatarDropdownOpen = false;
  }

  @action
  copyToClipboard(text) {
    MiscUtils.copyToClipboard(text);
  }

  @action
  inviteFromFacebook() {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?mode=message&u=' + this.pagePublicUrl,
      'sharer',
      this.getWindowFeatures()
    );
  }

  @action
  inviteFromTwitter() {
    let text = encodeURIComponent(
        __("See what I'm really sharing these days. Join me on MeWe - the social network with privacy!")
      ),
      url = encodeURIComponent(this.pagePublicUrl);

    window.open(
      'https://www.twitter.com/intent/tweet?text=' + text + '&url=' + url,
      'sharer',
      this.getWindowFeatures()
    );
  }

  @action
  addStory() {
    const choosePhotoArgs = {
      task: this.tasks.getTask('photo.story'),
      useNewCropper: true,
      pageId: this.page.id,
      onStoryCreated: () => {
        this.isStoryCreated = true;
      },
    };
    this.dynamicDialogs.openDialog('choose-photo', choosePhotoArgs);
  }
}
