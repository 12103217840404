import { Theme, FeedTypes } from 'mewe/constants';
import { showNewToMeWePopup, showGroupPreviewDialog } from 'mewe/utils/dialogs-common';

const getPostScopeAndId = (post) => {
  let res = { scope: null, scopeId: null };

  if (!post) {
    return res;
  }

  if (post.eventId) {
    // post with eventId is a post inside event, post with eventInfo is a post about created event (and still can have groupId or be in home feed)
    res.scope = Theme.EVENT;
    res.scopeId = post.eventId;
  } else if (post.groupId && post.groupId !== Theme.CONTACTS) {
    res.scope = Theme.GROUP;
    res.scopeId = post.groupId;
  } else if (post.privacymail || post.threadId) {
    res.scope = Theme.PRIVATEPOSTS;
    res.scopeId = post.threadId;
  } else if (post.pageId) {
    res.scope = Theme.PAGE;
    res.scopeId = post.pageId;
  } else {
    res.scope = Theme.CONTACTS;
    res.scopeId = Theme.CONTACTS;
  }

  return res;
};

const getHomeAggregatedFeed = (post) => {
  const { scope } = getPostScopeAndId(post);

  if (scope === Theme.GROUP) {
    return FeedTypes.GROUPS;
  }
  if (scope === Theme.PAGE) {
    return FeedTypes.PAGES;
  }

  return FeedTypes.CONTACTS;
};

export const getPostTheme = (post, theme) =>
  [FeedTypes.ALL, FeedTypes.DISCOVER].includes(theme) ? getPostScopeAndId(post).scope : theme;

export const postCustomInteraction = (dynamicDialogs, post, target = null, options = {}) => {
  if (post.isPublicContent) {
    showNewToMeWePopup(dynamicDialogs);
    return true;
  }

  if (shouldShowGroupPreview(post, target)) {
    showGroupPreviewDialog(dynamicDialogs, post?.group, {
      isDiscoverPostInteraction: true,
      isGroupPreview: true,
      context: options.context,
    });
    return true;
  }

  return false;
};

export const shouldShowGroupPreview = (post, target) => {
  if (post.discoverScope === 'group') {
    if (target) {
      // Skip group preview for specific interactions (emoji, share, undo hidden, options, translation)
      if (target.closest('.c-mw-postbar-emoji') && !target.closest('.all-tab')) return;
      if (target.closest('.postbar-share-button')) return;
      if (target.closest('.post-hidden-undo')) return;
      if (target.closest('.c-mw-translation')) return;
      if (target.closest('.post-options-opener') || target.closest('.post-options')) return;
      if (target.closest('.comment-options-wrapper') || target.closest('.comment-options')) return;
      if (target.closest('.postbar-reply-button')) return;
      if (target.closest('.comment_reply')) return;
      if (target.closest('.post-media_nav-btn')) return;
      if (target.closest('.c-mw-video-player')) return;
      if (target.closest('.play-initialiser')) return;
    }

    return true;
  }

  return false;
};

export default {
  getPostScopeAndId: getPostScopeAndId,
  getHomeAggregatedFeed: getHomeAggregatedFeed,
};
