import Component from '@glimmer/component';
import { action, computed, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import 'mewe/services/chat';
import PS from 'mewe/utils/pubsub';
import Storage from 'mewe/shared/storage';
import fetchGroups from 'mewe/fetchers/fetch-groups';
import { fetchFollowed } from 'mewe/fetchers/fetch-pages';
import fetchUpcomingEvents from 'mewe/fetchers/fetch-events';
import dispatcher from 'mewe/dispatcher';
import { addObserver, removeObserver } from '@ember/object/observers';
import { getCookieValue } from 'mewe/utils/version-utils';

let delay = 80;

export default class MwAppMainHeader extends Component {
  @service router;
  @service settings;
  @service account;
  @service analytics;
  @service dynamicDialogs;

  @tracked canShowInfo;
  @tracked isSmartSearchOpened = false;
  @tracked notificationsDropdownOpened = false;
  @tracked responsiveContentActiveType;
  @tracked isHeaderHidden = false;
  @tracked parent = null;
  @tracked userDropdownParent = null;
  @tracked isUserDropdownOpened = false;
  @tracked showChatsPopup = false;
  @tracked dropdownId = '';
  @tracked revision = 'current';
  element;

  constructor() {
    super(...arguments);
    this.isHeaderHidden = this.router.currentRouteName === 'app.delete-account';

    addObserver(this, 'eventsDropdownOpened', this.eventsDropdownOpenedChange);

    // always fetch events on init because new posts badge relies on in right now
    fetchUpcomingEvents('events', 'upcoming-events');

    this.revision = getCookieValue('revision');
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    if (this._clickHandler) {
      document.body.removeEventListener('click', this._clickHandler);
    }
    removeObserver(this, 'eventsDropdownOpened', this.eventsDropdownOpenedChange);
  }

  get showCartIndicator() {
    const storeState = this.args.model.storeState;

    // getter is important here, otherwise property won't update
    if (get(storeState, 'storeItemsLoaded')) {
      return storeState.cartItems.length;
    } else {
      let lsCartItems = [];
      try {
        lsCartItems = JSON.parse(Storage.get(Storage.keys.cartItems)) || [];
      } catch (e) {}

      return lsCartItems.length;
    }
  }

  @computed('settings.{loaded,notificationsHideInfo}', 'account.activeUser.notifications.canShowInfo')
  get canShowInfo() {
    if (!this.settings.loaded || this.settings.notificationsHideInfo) return false;
    return this.account.activeUser?.notifications?.canShowInfo;
  }

  @computed('events.items.@each.unreadPostsCount')
  get hasUnreadEvents() {
    return this.events.items.find((e) => e.unreadPostsCount > 0);
  }

  eventsDropdownOpenedChange() {
    if (!this.eventsDropdownOpened) {
      this.isEventPickerOpened = false;
    }
  }

  @action
  refreshMW() {
    if (this.router.currentRouteName !== 'app.myworld.index') return;
    PS.Pub('close.smart.search');
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  @action
  refreshGroups() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    later(this, fetchGroups, delay);
  }

  @action
  refreshPages() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    later(this, fetchFollowed, delay);
  }

  @action
  setSmartSearchState(isOpened) {
    this.isSmartSearchOpened = isOpened;
  }

  @action
  openSmartSearch() {
    PS.Pub('open.smart.search');
  }

  @action
  goToRoute() {
    if (this.responsiveContentActiveType) {
      this.responsiveContentActiveType = null;
    }
    PS.Pub('close.smart.search');
    this.dynamicDialogs.closeAll();
  }

  @action
  logout() {
    dispatcher.dispatch('app', 'logout');
  }

  @action
  toggleNotificationsDropdown() {
    const showMobileVersion = window.innerWidth < 768;

    if (
      (showMobileVersion && this.responsiveContentActiveType !== 'notifications') ||
      (!showMobileVersion && !this.notificationsDropdownOpened)
    ) {
      this.analytics.sendEvent('notificationCenterOpened', { has_unseen: !!this.args.model.notifications.unseenCount });
    }

    if (showMobileVersion) {
      PS.Pub('close.smart.search'); // for mobile web, it's possible to open notifications while SS is opened
      this.openResponsiveContent('notifications');
      return;
    }

    if (!this.notificationsDropdownOpened) {
      this.notificationsDropdownOpened = true;
    } else {
      this.notificationsDropdownOpened = false;
    }
  }

  @action
  setNotificationsDropdownOpened(value) {
    if (!value) {
      this.responsiveContentActiveType = null;
    }
    this.notificationsDropdownOpened = value;
  }

  @action
  openResponsiveContent(type) {
    if (this.responsiveContentActiveType !== type) {
      this.responsiveContentActiveType = type;
    } else {
      this.responsiveContentActiveType = null;
    }
  }

  @action
  openNotificationsRequest() {
    dispatcher.dispatch('notification', 'requestPermissions');
  }

  @action
  closeNotificationHeaderPermanently() {
    dispatcher.dispatch('notification', 'hidePermanently');
  }

  @action
  closeNotificationHeaderShort() {
    dispatcher.dispatch('notification', 'turnOffShort');
  }

  @action
  openHeaderPopup(type, evt) {
    // 620px is a breakpoint for mobile view where we don't show popup anymore
    if (window.innerWidth <= 620) return;

    const className = `.dropdown-opener--${type}`;
    const dropdownElement = document.getElementsByClassName(`main-header-${type}-dropdown`);

    if (dropdownElement.length > 0) {
      return;
    }

    this.closeUserDropdown();

    this.parent = this.element.querySelector(className);
    if (evt && evt.target.matches(':hover')) {
      this.dropdownId = type;
    }
  }

  @action
  closeHeaderPopup(type) {
    // clear dropdown only if close event is from the same dropdown,
    // otherwise moving mouse from one dropdown to another will close both
    if (type === this.dropdownId) {
      this.parent = null;
      this.dropdownId = '';
    }
  }

  @action
  openUserDropdown() {
    if (window.innerWidth < 768) {
      this.openResponsiveContent('user');
      return;
    }

    const className = '.dropdown-opener--user';
    const dropdownElement = document.getElementsByClassName('main-header-user-dropdown');

    if (dropdownElement.length > 0) return;

    this.userDropdownParent = this.element.querySelector(className);
    this.isUserDropdownOpened = true;
  }

  @action
  closeUserDropdown() {
    this.isUserDropdownOpened = false;
  }

  @action
  showPremiumDetails() {
    this.dynamicDialogs.openDialog('store/store-item-premium-dialog', {
      context: 'main_header_badge',
    });
  }

  @action
  toggleDarkTheme() {
    this.settings.saveTheme();
  }

  @action
  openFeedback() {
    dispatcher.dispatch('feedback', 'openFeedbackDialog');
  }

  @action
  storeButtonClicked() {
    this.analytics.sendEvent('buttonClicked', 'Store Button in Header');
  }

  @action
  clickOutsideDropdowns() {
    this.responsiveContentActiveType = null;
  }
}
