import ApiUtil from './api';

class Api extends ApiUtil {
  createFolder(params, groupId) {
    if (groupId && groupId !== 'mycloud') {
      return this.post('/doc/group/' + groupId + '/folder', {
        data: JSON.stringify(params),
      });
    } else {
      return this.post('/doc/mycloud/folder', {
        data: JSON.stringify(params),
      });
    }
  }

  removeFolder(folderId) {
    return this.del('/doc/folder/' + folderId);
  }

  moveFolder(nodeId, params) {
    return this.put('/doc/' + nodeId + '/move', {
      data: JSON.stringify(params),
    });
  }

  getRootFolderTree(params, groupId) {
    if (groupId === 'mycloud') {
      return this.get('/doc/mycloud/folder/root/tree', {
        data: params,
      });
    } else if (groupId === 'contacts') {
      return this.get('/doc/contacts/feed', {
        data: params,
      });
    } else {
      return this.get('/doc/group/' + groupId + '/folder/root/tree', {
        data: params,
      });
    }
  }

  getFolderTree(folderId, params) {
    return this.get('/doc/folder/' + folderId + '/tree', {
      data: params,
    });
  }

  getFilteredFeed(params, groupId) {
    if (groupId === 'contacts') {
      return this.get('/doc/contacts/feed', {
        data: params,
      });
    } else {
      return this.get('/doc/group/' + groupId + '/feed', {
        data: params,
      });
    }
  }

  uploadBlobAudio(blob) {
    // create filename with current date and time
    // format: '2024-03-15_14-30-22.mp3' (YYYY-MM-DD_HH-MM-SS.mp3)
    const now = new Date();
    const filename = now.getFullYear() + '-' +
      String(now.getMonth() + 1).padStart(2, '0') + '-' +
      String(now.getDate()).padStart(2, '0') + '_at_' +
      String(now.getHours()).padStart(2, '0') + '-' +
      String(now.getMinutes()).padStart(2, '0') + '-' +
      String(now.getSeconds()).padStart(2, '0') +
      '.mp3';

    let fd = new FormData();
    fd.append('files[]', blob, filename);

    return this.post('/audio', {
      data: fd,
      cache: false,
      contentType: false,
      processData: false,
    });
  }
}

export default new Api();
