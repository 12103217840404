import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import dispatcher from 'mewe/dispatcher';

export default class ChatMessageOptions extends Component {
  @tracked showOptionsBar = false;

  @action
  retrySending() {
    if (!this.args.message.isSending) {
      this.args.message.isSending = true;
      dispatcher.dispatch('chat', 'retrySending', this.args.thread, this.args.message.preId);
    }
  }

  @action
  toggleOptionsBar() {
    this.showOptionsBar = !this.showOptionsBar;
    this.args.onToggleOptionsBar();
  }
}
