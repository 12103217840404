import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { service } from '@ember/service';

export default class ProfileMainAvatar extends Component {
  @service dynamicDialogs;

  @tracked isAvatarDropdownOpen = false;

  @action
  userAvatarClick() {
    if (this.args.isPublicProfilePage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.args.hasEntries) {
      this.isAvatarDropdownOpen = true;
    } else {
      this.args.openProfilePhoto();
    }
  }

  @action
  closeAvatarDropdown() {
    this.isAvatarDropdownOpen = false;
  }

  @action
  openProfileStories() {
    this.args.openProfileStories();
    this.isAvatarDropdownOpen = false;
  }

  @action
  openProfilePhoto() {
    this.args.openProfilePhoto();
    this.isAvatarDropdownOpen = false;
  }
}
