import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AmbassadorsRouteComponent extends Component {
  @tracked scrollPosition = 0;
  @tracked screensLeftOffset = 0;
  @tracked windowWidth = window.innerWidth;

  scrollBreakpoint1 = 500;
  scrollBreakpoint2 = 1250;
  headerMaxScroll = 700;
  constructor() {
    super(...arguments);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  @action
  onScreensInsert(element) {
    this.screensElement = element;
  }

  @action
  handleResize() {
    this.windowWidth = window.innerWidth;
  }

  get screenOneStyle() {
    const isRtl = document.dir;
    let zIndex, translate, rotate, scale, position;


    // position range:
    // 0 - this.scrollBreakpoint1: 32% - 64%
    // this.scrollBreakpoint1 - this.scrollBreakpoint2: 64% - 0%

    // rotate range:
    // 0 - this.scrollBreakpoint1: 0 - 5deg
    // this.scrollBreakpoint1 - this.scrollBreakpoint2: 5deg - -5deg

    // scale range:
    // 0 - this.scrollBreakpoint1: 1 - 0.85
    // this.scrollBreakpoint1 - this.scrollBreakpoint2: 0.85 - 0.85

    if (this.scrollPosition < this.scrollBreakpoint1) {
      zIndex = this.scrollPosition < this.scrollBreakpoint1 - 100 ? 3 : 1;
      translate = `${32 + (this.scrollPosition / this.scrollBreakpoint1) * 32}%`;
      rotate = `${0 + (this.scrollPosition / this.scrollBreakpoint1) * 5}deg`;
      scale = `${1 - (this.scrollPosition / this.scrollBreakpoint1) * 0.15}`;
    } else if (this.scrollPosition < this.scrollBreakpoint2) {
      zIndex = 1;
      translate = `${64 - (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 64}%`;
      rotate = `${5 - (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 10}deg`;
      scale = `0.85`;
    } else {
      zIndex = 1;
      translate = '0';
      rotate = '-5deg';
      scale = '0.85';
    }

    if (isRtl) {
      translate = `-${translate}`;
      rotate = rotate.includes('-') ? rotate.replace('-', '') : `-${rotate}`;
    }

    return `transform: translateX(${translate}) rotateZ(${rotate}) scale(${scale}); z-index: ${zIndex}; ${position}`;
  }

  get screenTwoStyle() {
    const isRtl = document.dir;
    let zIndex, translate, rotate, scale, position;

    if (this.scrollPosition < this.scrollBreakpoint1) {
      zIndex = 2;
      translate = `${0 + (this.scrollPosition / this.scrollBreakpoint1) * 32}%`;
      rotate = `${-5 + (this.scrollPosition / this.scrollBreakpoint1) * 5}deg`;
      scale = `${0.85 + (this.scrollPosition / this.scrollBreakpoint1) * 0.15}`;
    } else if (this.scrollPosition < this.scrollBreakpoint2) {
      zIndex = this.scrollPosition < this.scrollBreakpoint2 - 100 ? 3 : 2;
      translate = `${32 + (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 32}%`;
      rotate = `${0 + (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 5}deg`;
      scale = `${1 - (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 0.15}`;
    } else {
      zIndex = 2;
      translate = '64%';
      rotate = '5deg';
      scale = '0.85';
    }

    if (isRtl) {
      translate = `-${translate}`;
      rotate = rotate.includes('-') ? rotate.replace('-', '') : `-${rotate}`;
    }

    return `transform: translateX(${translate}) rotateZ(${rotate}) scale(${scale}); z-index: ${zIndex}; ${position}`;
  }

  get screenThreeStyle() {
    const isRtl = document.dir;
    let zIndex, translate, rotate, scale, position;

    if (this.scrollPosition < this.scrollBreakpoint1) {
      zIndex = 1;
      translate = `${64 - (this.scrollPosition / this.scrollBreakpoint1) * 64}%`;
      rotate = `${5 - (this.scrollPosition / this.scrollBreakpoint1) * 10}deg`;
      scale = `0.85`;
    } else if (this.scrollPosition < this.scrollBreakpoint2) {
      zIndex = this.scrollPosition < this.scrollBreakpoint2 - 100 ? 1 : 3;
      translate = `${0 + (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 32}%`;
      rotate = `${-5 + (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 5}deg`;
      scale = `${0.85 + (this.scrollPosition - this.scrollBreakpoint1) / (this.scrollBreakpoint2 - this.scrollBreakpoint1) * 0.15}`;
    } else {
      zIndex = 3;
      translate = '32%';
      rotate = '0deg';
      scale = '1';
    }

    if (isRtl) {
      translate = `-${translate}`;
      rotate = rotate.includes('-') ? rotate.replace('-', '') : `-${rotate}`;
    }

    return `transform: translateX(${translate}) rotateZ(${rotate}) scale(${scale}); z-index: ${zIndex}; ${position}`;
  }

  get screensWrapperStyle() {
    const isRtl = document.dir;

    if (this.windowWidth <= 1140) {
      return `position: relative;`;
    }

    const fixedLeft = this.windowWidth / 2 - 40; // 40 is experimental value

    if (this.scrollPosition > this.scrollBreakpoint2) {
      const leftOverMax = window.innerWidth > 1400 ? (window.innerWidth - 1400) / 2 : 0;
      const absoluteLeft = fixedLeft < 650 ? fixedLeft : fixedLeft - leftOverMax;
      const topOverMax = this.windowWidth > 1400 ? 200 : 160;
      return `position: absolute; top: ${this.scrollBreakpoint2 - topOverMax}px; ${isRtl ? 'right' : 'left'}: ${absoluteLeft}px;`;
    } else {
      return `position: fixed; ${isRtl ? 'right' : 'left'}: ${fixedLeft}px;`;
    }
  }

  get introHeaderStyle() {
    if (this.windowWidth <= 1140) {
      return `position: relative; top: 0;`;
    }

    if (this.scrollPosition > this.headerMaxScroll) {
      return `position: relative; top: ${this.headerMaxScroll}px;`;
    }
  }

  get introFooterStyle() {
    if (this.windowWidth <= 1140) {
      return `position: relative;`;
    }

    if (this.scrollPosition > this.headerMaxScroll) {
      return `margin-top: 840px;`;
    }
  }

  @action
  handleScroll() {
    this.scrollPosition = window.scrollY;

    if (this.scrollPosition <= this.scrollBreakpoint2) {
      const isRtl = document.dir;
      if (isRtl) {
        this.screensLeftOffset = this.screensElement.getBoundingClientRect().right;
      } else {
        this.screensLeftOffset = this.screensElement.getBoundingClientRect().left;
      }
    }
  }

  @action
  joinAction() {
    window.location.href = 'https://zpzfa0jymi7.typeform.com/to/pjfL8Iio?utm_source=lp';
  }
}
