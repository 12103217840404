import { assert } from '@ember/debug';
import ApiUtil from './api';

class Api extends ApiUtil {
  prepareContent(params) {
    if (params && (params.text || params.audio || params.photo || params.video || params.doc)) {
      return this.post('/mycloud/bundle/prepare', {
        data: JSON.stringify(params),
      }); // TODO: 'apiRoot3' once backend ready
    }
  }

  getActiveGroupsPosts(params) {
    return this.get('/mycloud/groups/posts', { data: params }, 'apiRoot3');
  }

  getActiveGroupsMedias(params) {
    return this.get(
      '/mycloud/groups/medias',
      {
        data: Object.assign({}, params, { skipVideos: false }), // TODO need to be fixed on backend
      },
      'apiRoot3'
    );
  }

  getActiveGroupsFiles(params) {
    return this.get(
      '/mycloud/groups/files',
      {
        data: params,
      },
      'apiRoot3'
    );
  }

  deleteMedia(params) {
    return this.post(
      '/mycloud/remove/media',
      {
        data: params,
      },
      'apiRoot3'
    );
  }

  deleteFiles(ids) {
    assert('Missing ids in deleteFiles', ids);
    return this.del('/mycloud/remove/files?' + this.toUrlParams({ fileIds: ids.toString(',') }), {}, 'apiRoot3');
  }


  getAudioFeeds(params, nextPageUrl) {
    if (nextPageUrl) {
      return this.getNextPageOr(nextPageUrl);
    } else {
      return this.get(
        '/mycloud/audiofeed',
        {
          data: params,
        },
        'apiRoot3'
      );
    }
  }

  deleteAudio(ids) {
    return this.del('/mycloud/remove/audios?' + this.toUrlParams({ audioIds: ids.toString(',') }), {}, 'apiRoot3');
  }

  renameAudio(id, params) {
    return this.put(`/mycloud/audio/${id}/rename`, {
      data: JSON.stringify(params),
    }, 'apiRoot3');
  }
}

export default new Api();
