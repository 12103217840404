import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { inject as service } from '@ember/service';

export default class NsfwBlur extends Component {
  @service dynamicDialogs;

  imageSize = this.args.imageSize || '150x150';

  @tracked isBlurred = this.args.isBlurred;

  get isFeedSkin() {
    return this.args.skin === 'feed';
  }

  @action
  updateBlur() {
    this.isBlurred = this.args.isBlurred;
  }

  @action
  reveal() {
    // unauthorised user previewing public content, login prompt will be shown in the feed
    if (this.args.isPublicContent) {
      return;
    }

    this.isBlurred = false;
    if (this.args.onReveal) {
      this.args.onReveal();
    }
  }
}
