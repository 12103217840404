import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, computed, get } from '@ember/object';
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class ChatInfoMedia extends Component {
  @reads('args.thread') thread;

  @service router;

  @tracked feed;
  @tracked photostreamContainerWidth;

  @action
  onInsert(element) {
    this.photostreamContainerWidth = element.offsetWidth || 340;
    console.log('photostreamContainerWidth', this.photostreamContainerWidth);
  }

  @computed('thread.id', 'args.mediaOpened', 'args.chatInfoActive')
  get showPhotos() {
    return this.args.chatInfoActive && this.thread.id && this.args.mediaOpened === 'photos';
  }

  @computed('thread.id', 'args.mediaOpened')
  get showFiles() {
    return this.thread.id && this.args.mediaOpened === 'files';
  }

  @action
  openMediaTab(tab) {
    this.feed = null;
    this.args.openMediaTab(tab);
  }

  @action
  openProfile(user) {
    const groupId = this.thread.chatType === 'GroupChat' ? this.thread.id : null;
    const eventId = this.thread.chatType === 'EventChat' ? this.thread.event.id : null;

    if (user) {
      const userId = get(user, 'id') || get(user, 'userId');

      if (eventId) {
        this.router.transitionTo('app.event.attendees.profile', eventId, userId);
      } else if (groupId && groupId != 'contacts') {
        this.router.transitionTo('app.group.members.profile', groupId, userId);
      } else {
        this.router.transitionTo('app.publicid', userId);
      }
    }
  }
}
