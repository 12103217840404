import Model from 'mewe/utils/store-utils/model/model.js';
import { attr } from 'mewe/utils/store-utils/model/attribute';
import { computed } from '@ember/object';

var model = Model.extend({
  id: attr('string'),
  publicLinkId: attr('string'),
  firstName: attr('string'),
  fprint: attr('string'),
  fingerprint: attr('string'),
  lastName: attr('string'),
  name: attr('string'),
  public: attr('boolean'),
  badges: attr('object'),
  _links: attr('object'),
  profileId: computed('publicLinkId', function () {
    return this.publicLinkId;
  }),
});

model.reopenClass({
  resourceName: 'simple-user',
  aliases: {
    publicUrl: 'publicLinkId',
  },
});

export default model;
