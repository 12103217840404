import Component from '@glimmer/component';
import EmberObject, { action, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { includes, each } from 'lodash';
import { addObserver, removeObserver } from '@ember/object/observers';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import DocumentsApi from 'mewe/api/documents-api';
import PS from 'mewe/utils/pubsub';
import Scrolling from 'mewe/utils/scrolling-utils';
import isUndefined from 'mewe/utils/isUndefined';
import { Theme } from 'mewe/constants';
import Document from 'mewe/stores/models/document-model';
import { sortMediaOptions } from 'mewe/utils/options';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { run } from '@ember/runloop';
import { A } from '@ember/array';

const ROOT = {
  id: 'root',
  name: __('All files'),
};

export default class MwDocumentsService extends Component {
  @service dynamicDialogs;
  @service account;

  @tracked docs = A();
  @tracked inProgress = true;
  @tracked canCreateFolder = null;
  @tracked canPost = null;
  @tracked breadcrumbs = A([ROOT]);
  @tracked hashTag = null;
  @tracked searchText = '';
  @tracked oldSearchText = '';
  @tracked sorting;
  @tracked sortOptions;

  renderedRecentPostIds = A();
  offset = 0;
  maxResults = 20;
  docsCount = 0;
  folderCount = 0;
  renderingNewFolder = true;
  filterSet = null;
  scrolling = Scrolling();

  get group() {
    return this.args.group;
  }

  constructor() {
    super(...arguments);

    this.args.setCurrentFolder(ROOT);

    this.sortOptions = sortMediaOptions({ bySize: true, byName: true, byType: true });
    this.sorting = this.sortOptions.subOptions[0];

    PS.Sub('documents.reload', () => this.reRenderView());
    this.wsDocEditBind = this.wsDocEdit.bind(this);
    PS.Sub('doc.edit', this.wsDocEditBind);

    if (this.args.isMyCloud || this.group || this.args.myCloudGroupId) {
      this.create();
    } else {
      // in group documents wait until group object is present (SG-30183)
      addObserver(this, 'args.group', this.groupLoaded);
    }
  }

  @action
  onDestroy() {
    PS.Unsub('documents.reload', this.reRenderView);
    PS.Unsub('doc.edit', this.wsDocEditBind);
    this.scrolling.unbindScrollDown();
  }

  get isInMyCloud() {
    return this.args.isMyCloud || this.args.myCloudGroupId;
  }

  get currentGroupId() {
    return this.group?.id || Theme.MYCLOUD;
  }

  get isGroup() {
    return this.group?.id && this.groupId !== Theme.CONTACTS && !this.args.isMyCloud;
  }

  groupLoaded() {
    if (this.group) {
      this.create();
      removeObserver(this, 'args.group', this.groupLoaded);
    }
  }

  create() {
    this.scrolling.unbindScrollDown();

    this.canPost = this.args.isMyCloud || this.group?.canPost;
    this.canCreateFolder = this.args.isMyCloud || (this.group?.canPost && this.group?.canPostWithoutModeration);

    this.renderView(true);
  }

  @action
  orderMedia(option) {
    this.sorting = option;
    this.reRenderView();
  }

  reRenderView() {
    this.offset = 0;
    this.inProgress = true;
    this.docs = A();
    this.renderView(!this.filterSet);
  }

  renderView(isNodes, update) {
    let options = null,
      callback = (data) => {
        if (this.isDestroyed || this.isDestroying) return;

        this.folderCount = 0;
        this.docsCount = 0;

        // don't allow moving docs in Group files opened in MC as we don't show folders there
        // backend returns canMove: true in this case but let's leave it this way until some bigger rewrite
        each(data.list, (d) => {
          d.canMove = d.canMove && !this.args.myCloudGroupId;
        });

        let serializer = JSONSerializer.create(),
          documents = serializer.deserializeMany(A(), Document, data.list);

        each(documents, (node) => {
          // folders don't have 'post' so we add it to operate on it
          if (!node.post) {
            node.set('post', EmberObject.create());
          }

          if (node.nodeIcon === 'folder') {
            node.setProperties({
              isFolder: true,
              'post.canMove': node.canMove,
              'post.canRemove': node.canRemove,
            });
            this.folderCount += 1;
          } else {
            node.setProperties({
              'post.postItemId': node.postItemId,
            });
          }

          this.docsCount = data.list.length - this.folderCount;

          if (this.args.myCloudGroupId) {
            const group = {
              id: this.args.myCloudGroupId,
              name: this.args.groupName,
            };
            node.setProperties({
              group: group,
              'post.groupId': this.args.myCloudGroupId,
              'post.group': group,
            });
          } else if (this.args.isMyCloud) {
            node.set('group', { id: Theme.MYCLOUD });
          } else if (this.group) {
            node.setProperties({
              group: this.group,
              'post.groupId': this.group.id,
              'post.group': this.group,
            });
          }

          if (!node.canRemove && !node.canMove) {
            node.set('isLimited', true);
          }
        });

        this.renderDocs(isNodes, documents, update);

        if (data.list.length >= this.maxResults) {
          this.offset = this.offset + this.maxResults;
          this.scrolling.bindScrollDown(() => this.renderView(true, true));
        } else {
          this.scrolling.unbindScrollDown();
        }
      };

    if (!update) this.offset = 0;

    if (isNodes) {
      this.filterSet = false;
      options = {
        maxResults: this.maxResults,
        offset: this.offset,
        sorting: this.sorting.orderBy,
        sortAsc: this.sorting.orderAsc,
      };

      // needed for pagination, backend uses two queries there, one for folders and one for docs
      // with maxResults = 20 we can get up to 40 results, 20 folders and 20 docs
      // so if number of folders or docs < maxResults we send param below to get only files or only folders - MS
      if (this.folderCount < this.maxResults && !this.renderingNewFolder) {
        options.filesOnly = true;
      } else if (this.docsCount < this.maxResults && !this.renderingNewFolder) {
        options.foldersOnly = true;
      }
    } else {
      options = {
        maxResults: this.maxResults,
        offset: this.offset,
        sorting: this.sorting.orderBy,
        sortAsc: this.sorting.orderAsc,
      };
    }

    const searchText = this.searchText.trim();
    if (searchText) {
      options.searchFileName = searchText;
    }

    if (this.args.myCloudGroupId) {
      // we want to display only user's docs
      options.ownerIds = this.account.activeUser.id;
    }

    let apiCall = (call) =>
      call.then(callback).finally(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this.inProgress = false;
      });

    if (isNodes) {
      this.filteredFeed = false;
      if (!this.args.currentFolder || this.args.currentFolder.id === 'root') {
        if (this.args.myCloudGroupId) {
          apiCall(DocumentsApi.getFilteredFeed(options, this.args.myCloudGroupId));
        } else if (!this.args.isMyCloud) {
          apiCall(DocumentsApi.getRootFolderTree(options, this.currentGroupId));
        } else {
          apiCall(DocumentsApi.getRootFolderTree(options, Theme.MYCLOUD));
        }
      } else {
        apiCall(DocumentsApi.getFolderTree(this.args.currentFolder.id, options));
      }
    } else {
      this.filteredFeed = true;
      this.filterSet = true;
      apiCall(DocumentsApi.getFilteredFeed(options, this.args.myCloudGroupId || this.currentGroupId));
    }
  }

  renderDocs(isNodes, data, update) {
    var role = this.group?.role,
      currentUserIsOwnerOrAdmin = role === 'Owner' || role === 'Admin',
      currentUserId = this.account.activeUser.id;

    each(data, (doc) => {
      if (doc.isFolder) {
        if (doc.post && doc.post.canRemove !== undefined) {
          doc.set('canRemove', doc.post.canRemove);
        } else {
          doc.set('canRemove', currentUserId === doc.userId || currentUserIsOwnerOrAdmin);
        }
      }
    });

    //processing docs
    data.map((doc) => {
      var dataPost = isNodes ? doc.post : doc;
      if (!dataPost) {
        return doc;
      }

      return this.processDoc(doc, dataPost, this.currentGroupId);
    });

    this.inProgress = false;

    if (update) {
      this.docs.pushObjects(data);
    } else {
      this.docs = data;
    }
  }

  processDoc(doc, dataPost, groupId) {
    doc.canPost = this.canPost;

    if (doc.post) {
      set(doc, 'post.permissions', { remove: doc.canRemove });
      if (this.args.myCloudGroupId || this.args.isMyCloud) {
        set(doc, 'post.owner', this.account.activeUser);
      }
    }

    if (doc.post && doc.post.document) {
      if (doc.post.document.size) {
        doc.post.document.set('size', this.convertSize(doc.post.document.size));
      }
      if (doc.post.document._links) {
        doc.docLength = doc.post.document.size;
        doc.url = EnvironmentUtils.getMediaHost(true) + doc._links.file.href + '?download';
      }
      doc.docType = doc.post.document.type;
    }

    if (!get(doc, 'owner')) set(doc, 'owner', get(dataPost, 'owner') || get(dataPost, 'user'));
    if (doc.isFolder) doc.set('nodeIcon', 'folder');

    if (this.args.isMyCloud) {
      doc.mycloud = true;

      if (this.args.myCloudGroupId) doc.canMove = false;
    }

    doc.groupId = groupId;

    return doc;
  }

  wsDocEdit(docData) {
    this.docs.forEach((docPost) => {
      let doc = get(docPost, 'post.document');
      if (!doc) return;

      if (get(doc, 'id') === docData.id) {
        run(() => {
          if (!isUndefined(docData.name)) {
            set(docPost, 'name', docData.name);
            set(doc, 'name', docData.name);
          }
          if (!isUndefined(docData.length)) {
            set(docPost, 'docLength', this.convertSize(docData.length));
            set(doc, 'size', docData.length);
          }
          if (!isUndefined(docData.type)) {
            set(docPost, 'docType', docData.type);
            set(doc, 'type', docData.type);
          }
        });
      }
    });
  }

  loadDocuments(searchText, oldSearchText) {
    if (searchText) {
      if (searchText != oldSearchText) {
        this.offset = 0;
      }
      this.filterSet = true;
      this.renderView(false);
    } else {
      this.offset = 0;
      this.filterSet = false;
      this.renderView(true);
    }
  }

  addDocument(data) {
    var parentId = data.parentId || 'root';

    var canRender = (parentId == this.args.currentFolder.id && !data.pending) || data.canRender;
    if (includes(this.renderedRecentPostIds, data.id)) {
      canRender = false;
    }

    if (canRender) {
      this.renderedRecentPostIds.push(data.id);
      data.groupId = this.currentGroupId;
      if (data.commentsCount === undefined) data.commentsCount = 0;
      if (data.comments === undefined) data.comments = A();

      data.canMove = this.canCreateFolder;
      data.document.size = this.convertSize(data.document.size);

      if (!data.post) {
        //for WS
        data.post = data;
      }

      this.docs.unshiftObject(data);
    }
  }

  convertSize(size) {
    var kilobyte = 1024,
      megabyte = 1048576,
      convertedSize,
      convertedString = '';

    if (size <= kilobyte) {
      convertedString = size + ' B';
      return convertedString;
    } else if (size > kilobyte && size < megabyte) {
      convertedSize = size / kilobyte;
      convertedString = convertedSize.toFixed(2) + ' KB';
      return convertedString;
    } else if (size >= megabyte) {
      convertedSize = size / megabyte;
      convertedString = convertedSize.toFixed(2) + ' MB';
      return convertedString;
    }
  }

  @action
  showAllPosts() {
    this.showHashTag(null);
  }

  @action
  showHashTag(ht) {
    this.hashTag = ht;
    this.renderView(false);
  }

  @action
  uploadDoc() {
    openPostbox(
      {
        target: this.group ? Theme.GROUP : Theme.MYCLOUD,
        groupId: this.group ? this.group?.id : null,
        group: this.group,
        theme: this.group ? Theme.GROUP : Theme.MYCLOUD,
        isFileuploadDialog: true,
        showFilesButton: true,
        showControlsButtons: false,
        folder: this.args.currentFolder,
      },
      this.dynamicDialogs
    );
  }

  @action
  createFolder() {
    const rootId = this.args.currentFolder?.id || 'root';

    this.dynamicDialogs.openDialog('create-folder-dialog', {
      rootId: rootId,
      groupId: this.group?.id || Theme.MYCLOUD,
      groupName: this.group?.name || __('My Cloud'),
    });
  }

  @action
  openFolder(folder) {
    if (this.args.currentFolder?.id === folder.id) return;

    run(() => {
      let crumbs = this.breadcrumbs,
        isOpeningSubfolder = !crumbs.includes(folder);

      if (isOpeningSubfolder) {
        crumbs.pushObject(folder);
      } else {
        let arr = A();

        crumbs.find((c) => {
          if (c.id !== folder.id) arr.push(c);
          else return true;
        });

        arr.pushObject(folder);
        this.breadcrumbs = arr;
      }

      this.renderingNewFolder = true;
      this.offset = 0;
      this.rootId = folder.id;

      this.args.setCurrentFolder(folder);

      this.create();
    });
  }

  @action
  doSearch() {
    const timeoutId = this.searchTimeoutId,
      oldSearchText = this.oldSearchText;

    this.oldSearchText = this.searchText;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.inProgress = true;
    this.searchTimeoutId = setTimeout(() => {
      this.loadDocuments(this.searchText, oldSearchText);
    }, 300);
  }

  @action
  clearSearch() {
    this.searchText = '';
    this.loadDocuments();
  }
}
