import { inject as service } from '@ember/service';
import { next, later } from '@ember/runloop';
import EmberObject, { action, computed, set } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';

import Mentions from 'mewe/utils/mentions-utils';
import ChatUploader from 'mewe/utils/chat-uploader';
import ChatUtils from 'mewe/utils/chat-utils';
import ChatStore from 'mewe/stores/chat-store';
import MwChatBase from 'mewe/pods/components/chats/mw-chat-base';
import { isMobile } from 'mewe/shared/utils';
import PS from 'mewe/utils/pubsub';
import toServer from 'mewe/stores/text-parsers/to-server';
import { isDefined } from 'mewe/utils/miscellaneous-utils';
import dispatcher from 'mewe/dispatcher';

export default class MwChat extends MwChatBase {
  @service chat;
  @service account;
  @service dynamicDialogs;

  @tracked chatCallDropdownOpened;
  @tracked attachmentDropdownOpened;
  @tracked videoFiles = A();

  tabIndexOffset = 1000;
  loadNewerMessagesOffset = 500;
  sendButtonForMobile = isMobile() || window.innerWidth <= 980;

  @action
  onInsert() {
    super.onInsert(...arguments);

    // no need to initialize fileupload etc if new message form is not visible and can't send msg
    if (this.thread?.isNewChat) {
      const autocompleteEl = this.element.querySelector('.user-autocomplete .h-input_search');
      if (autocompleteEl) {
        autocompleteEl.focus();
      }
    }

    // noFocusAfterOpen - do not focus when chat pops up after new WS message
    // or after opening chats from LS after page load
    if (this.thread?.noFocusAfterOpen) {
      set(this, 'thread.noFocusAfterOpen', false);
    } else {
      this.focus();
    }

    if (this.thread?.messages?.length === 0) {
      next(() => {
        this.registerNewMsgMentions();
      });
    }

    this.storeObserver(this, 'thread.arrivedWsMsgsSinceModelCreated', this.arrivedMessagesObserver);
    this.storeObserver(this, 'thread.chatFocused', this.focusChat);
    this.storeObserver(this, 'thread.expandedChatSize', this.sizeChanged);
    this.storeObserver(this, 'thread.minimized', this.sizeChanged);
    this.storeObserver(this, 'state.openedThreads.length', this.openedThreadsChange);
    this.storeObserver(this, 'audioRecordingBlob', this.audioRecordingBlobChanged);
    this.storeObserver(this, 'thread.selectedItems.length', this.newChatParticipantsChanged);

    this.sizeChanged(); // initial call right after inserting
    this.bindScroll();
    this.bindTextareaFocus();
    this.initUploads();
    this.setMentionsStrategy();
    this.setTabIndex();
    this.args.chatRendered?.();
  }

  @action
  onDestroy() {
    super.onDestroy(...arguments);

    const thread = this.thread;

    if (thread) {
      let openedThread = A(this.state.openedThreads).find((t) => t.id === thread.id);

      if (!openedThread) {
        set(this, 'thread.open', false);
      }

      if (thread.disabledClosed) {
        ChatStore.send('removeThread', thread);
      }
    }
  }

  didRenderAllMessages() {
    super.didRenderAllMessages(...arguments);
    this.refreshTextcomplete();
  }

  @computed('isSmallChat', 'thread.expandedChatSize')
  get chatSizeType() {
    if (this.isSmallChat) return 'smallChat';
    else if (this.thread?.expandedChatSize) return 'expandedChat';
  }

  @computed('thread.{minimized,expandedChatSize}')
  get isSmallChat() {
    return !this.thread?.minimized && !this.thread?.expandedChatSize;
  }

  @computed('thread.minimized', 'isChatWindow')
  get isMinimizedSmallOrExpandedChat() {
    return this.thread?.minimized && !this.isChatWindow;
  }

  @computed('thread.isNewChat', 'thread.selectedItems.length')
  get shouldDisplaySendForm() {
    // false if it is new chat and no participants selected
    return !this.thread?.isNewChat || this.thread?.selectedItems?.length > 0;
  }

  @computed('thread.minimized', 'isMaximizing')
  get threadIsMinimizedOrMaximizing() {
    return this.thread?.minimized || this.isMaximizing;
  }

  @computed('account.activeUser.sendDMByEnter', 'chatSizeType')
  get sendWithEnter() {
    return this.chatSizeType !== 'expandedChat' || this.account.activeUser.sendDMByEnter;
  }

  @computed('isAudioConverting', 'isAudioRecording', 'isSendingIntervalActive')
  get isSendingDisabled() {
    return this.isAudioRecording || this.isAudioConverting || this.isSendingIntervalActive;
  }

  @computed('chatOptionsAvailable', 'thread.isChatRequest')
  get showChatInfoButton() {
    return this.chatOptionsAvailable && !this.thread?.isChatRequest;
  }

  @computed('thread.waitingForCreation', 'thread.notContactNotClosed')
  get showChatRequestControls() {
    return !this.thread?.waitingForCreation && this.thread?.notContactNotClosed;
  }

  // coommon, analyse
  arrivedMessagesObserver() {
    if (this.firstMessagesRendered && isDefined(this.newWsMsgsCount) && this.thread?.arrivedWsMsgsSinceModelCreated) {
      this.newWsMsgsCount += 1;
    }
  }

  focusChat() {
    if (this.thread?.chatFocused) {
      this.focus();
    }
  }

  sizeChanged() {
    if (this.thread?.minimized) return;

    if (!this.thread?.expandedChatSize) {
      this.chatInfoActive = false;
    }

    if (this.thread?.chatFocused) {
      this.focus(); // just focus textarea
    } else {
      dispatcher.dispatch('chat', 'focusChat', this.thread);
    }

    if (this.wasScrolledToBottom) {
      this.scrollDown(true, true);
    }
  }

  newChatParticipantsChanged() {
    if (this.thread?.selectedItems?.length) {
      let firstSelectedItem = this.thread?.selectedItems[0];

      // if it is group, open group chat and hide chat creation
      if (firstSelectedItem.group) {
        const threadId = firstSelectedItem.group?.id;
        this.closeSmallChat();
        this.chat.openThreadById(threadId, {
          addTemporarilyToStore: true,
          expandedChatSize: this.thread?.expandedChatSize,
        });
      } else {
        const participants = this.thread?.selectedItems.map((i) => i.user);
        const options = {
          isNewChat: true,
          expandedChatSize: this.thread?.expandedChatSize,
        };

        // open chat with user if it exists
        // for multiuser chats we don't do this - we allow creating multiple chats with same participants
        if (this.thread?.selectedItems?.length === 1) {
          this.chat.openThreadByParticipants(participants, options);
        } else {
          ChatStore.send('createThreadForParticipants', participants, options);
        }
      }
    } else if (this.thread?.selectedItems) {
      ChatStore.send('setEmptyThreadToNewChat', { expandedChatSize: this.thread?.expandedChatSize });
    }
  }

  openedThreadsChange() {
    this.setTabIndex();
  }

  audioRecordingBlobChanged() {
    const blob = this.audioRecordingBlob;

    if (!blob) {
      this.audioFileId = null;
      return;
    }

    this.isAudioVoice = true;

    next(() => {
      if (this.voiceRecordingVisible) {
        blob.isVoice = true;
        this.chatAudioupload.add([blob]);
      }
      this.isAudioVoice = false;
      this.focus();
    });
  }

  setTabIndex() {
    let index = this.state.openedThreads.indexOf(this.thread);

    if (index > -1) {
      this.tabIndex = this.tabIndexOffset - index;
    }
  }

  initUploads() {
    if (this.canChat) {
      const commonUploaderParams = {
        chatEl: this.element,
        storeUploadRequest: this.storeUploadRequest.bind(this),
        setUploadInProgress: this.setUploadInProgress.bind(this),
        storeEventListener: this.storeEventListener.bind(this),
      };

      this.chatPhotoupload = ChatUploader.create({
        ...commonUploaderParams,
        formEl: this.element.querySelector('.chat-form-upload-photo'),
        dropZoneEl: this.element,
        pasteZoneEl: this.element.querySelector('.chat_send-form_textarea'),
        autoUpload: true,
        isImageUpload: true,
        send: this.sendMessage.bind(this),
        cancelUpload: this.cancelUpload.bind(this),
      });

      this.chatFileupload = ChatUploader.create({
        ...commonUploaderParams,
        formEl: this.element.querySelector('.chat-form-upload-file'),
        dropZoneEl: this.element,
        pasteZoneEl: null,
        autoUpload: true,
        isFileUpload: true,
        send: this.sendMessage.bind(this),
      });

      this.chatAudioupload = ChatUploader.create({
        ...commonUploaderParams,
        formEl: this.element.querySelector('.chat-form-upload-audio'),
        autoUpload: false,
        isFileUpload: true,
        onFileLoaded: this.onAudioFileLoaded.bind(this),
      });

      this.chatVideoupload = ChatUploader.create({
        ...commonUploaderParams,
        formEl: this.element.querySelector('.chat-form-upload-video'),
        dropZoneEl: this.element,
        pasteZoneEl: this.element.querySelector('.chat_send-form_textarea'),
        autoUpload: true,
        isVideoUpload: true,
        send: this.sendMessage.bind(this),
        currentUserId: this.account.activeUser.id,
        onFileAdded: this.onVideoFileAdded.bind(this),
        dynamicDialogs: this.dynamicDialogs,
      });
    }
  }

  focus() {
    let activeEl = document.activeElement;

    // don't focus, if user is already focused in another input, textarea or text editor, don't kidnap his focus
    let canFocusNewChat =
      !activeEl ||
      (activeEl.tagName !== 'INPUT' && activeEl.tagName !== 'TEXTAREA' && !activeEl.classList.contains('ql-editor'));

    if (canFocusNewChat) {
      let focusedChat = A(this.state.openedThreads).find((t) => t.chatFocused);

      if (!focusedChat || focusedChat.id === this.thread?.id) {
        if (this.editor) {
          // https://sentry.qa-groupl.es/mewe/javascript/issues/2033/
          this.editor.focus();
        }
      }
    }
  }

  registerNewMsgMentions(existingMentions) {
    const textareaEl = this.getChatTextareaElement();

    const doRegisterNewMsgMensions = (textarea) => {
      if (isDefined(textarea)) {
        this.mentionsStrategy = Mentions.createTextCompleteStrategy(this.getMentionsScope());

        if (existingMentions) {
          this.mentionsStrategy.mentions = existingMentions;
        } else if (this.thread?.mentions?.length) {
          this.mentionsStrategy.mentions = this.thread?.mentions;
        }
      }
    };

    if (isDefined(textareaEl)) {
      doRegisterNewMsgMensions(textareaEl);
    } else {
      // textarea sometimes is not defined at the moment of execution,
      // small workaround without using recursion.
      next(() => {
        doRegisterNewMsgMensions(this.getChatTextareaElement());
      });
    }
  }

  onAudioFileLoaded(tempId, id) {
    this.audioUploaded = true;
    this.audioFileId = id;
  }

  setUploadInProgress(value, tempId) {
    let files = this.filesInProgress,
      file = files.find((f) => f.tempId === tempId);

    if (file) {
      if (file.isVoice) {
        this.audioUploaded = false;
      } else {
        if (value > file.progress) {
          if (value == 100) {
            files.removeObject(file);
          } else {
            set(file, 'progress', value);
          }
        }
      }
    } else if (tempId) {
      files.pushObject(
        EmberObject.create({
          tempId: tempId,
          progress: value,
        })
      );
    } else if (value === false) {
      set(this, 'filesInProgress', A());
    }
  }

  doExpand() {
    this.closeSmallChat();

    // expanding sidebar group chat should unhide it if it's hidden
    dispatcher.dispatch('chat', 'unhideGroupChat', this.thread);
  }

  refreshTextcomplete() {
    if (this.isDestroying || this.isDestroyed) return;

    let existingMentions = A();

    if (this.thread?.mentions?.length) {
      existingMentions = this.thread?.mentions;
    } else {
      existingMentions = this.mentionsStrategy?.mentions;
    }

    this.registerNewMsgMentions(existingMentions);
  }

  shouldShowAttachmentAlert() {
    return (
      this.filesInProgress.length > 0 ||
      this.audioRecordingBlob ||
      this.audioFileId ||
      this.isAudioRecording ||
      this.isAudioConverting
    );
  }

  onEscKey() {
    dispatcher.dispatch('chat', 'closeSmallChat', this.thread);
    this.onTabKey(); // switch to next chat after closing current one
  }

  @action
  onClick(event) {
    // if text editor was clicked, it is desired to be at clicked position
    if (event.target.isContentEditable) {
      return false;
    }

    if (event.target.matches('.add-gif-btn, .pp-button, .no-chat-focus')) {
      return false;
    }

    let sel = getSelection().toString();
    if (!sel) {
      // prevent focusAndMarkAsRead when clicked inside text formatting element but don't prevent click event completely
      if (!event.target.closest('.chat-text-formatting-btn, .chat-info')) {
        this.focusAndMarkAsRead();
      }
    }
  }

  @action
  expand() {
    dispatcher.dispatch('chat', 'expandChatSize', this.thread);
  }

  @action
  toggleChatScale() {
    this.wasScrolledToBottom = this.isScrolledBottom(0, 0);

    if (this.thread?.expandedChatSize) {
      dispatcher.dispatch('chat', 'collapseChatSize', this.thread);
      if (this.chatInfoActive) this.chatInfoActive = false;
    } else {
      dispatcher.dispatch('chat', 'expandChatSize', this.thread);
    }

    this.formattingDropdownOpened = false;

    this.refreshTextcomplete();
  }

  @action
  closeSmallChat(doNotMarkAsRead = false, isClose = false) {
    const confirmClose = (doNotMarkAsRead) => {
      if (this.thread) {
        set(this, 'thread.open', false);
        set(this, 'thread.expandedChatSize', false);
        set(this, 'thread.minimized', false);
      }

      if (!doNotMarkAsRead) {
        this.markAsRead();
      }
      dispatcher.dispatch('chat', 'closeSmallChat', this.thread);

      PS.Pub('close.dropdowns');
    };

    if (isClose) {
      if (this.thread?.isReceivedChatRequest) {
        dispatcher.dispatch('app', 'showDYKpopup', 'chatRequest');
      }
    }

    if (this.shouldShowAttachmentAlert()) {
      ChatUtils.uploadCancelAlert(this.dynamicDialogs, confirmClose.bind(this, doNotMarkAsRead));
    } else {
      confirmClose(doNotMarkAsRead);
    }
  }

  onVideoFileAdded(file) {
    this.videoFiles.pushObject(file);
  }

  @action
  sendMessageClickAction() {
    this.sendMessage();
  }

  @action
  sendMessage(attachmentId, videoUploadData) {
    const isSendingAttachment = attachmentId || videoUploadData;

    if (this.isSendingDisabled && !isSendingAttachment) return;

    this.attachmentDropdownOpened = false;

    // prevent double send/enter press by blocking send action for a while
    this.isSendingIntervalActive = true;
    later(() => {
      if (this.isDestroying || this.isDestroyed) return;
      this.isSendingIntervalActive = false;
    }, 200);

    let message = (this.thread?.newMessage || '').trim();

    message = toServer(message, {
      mentionsStrategy: this.mentionsStrategy,
      parseNativeMarkdown: true,
    });

    let attachmentIds = A();
    let videoFiles = A();

    if (videoUploadData) {
      const videoFileToSend = this.videoFiles.find((f) => f.tempId === videoUploadData.tempId);
      if (videoFileToSend) {
        videoFileToSend.videoUploadFolder = videoUploadData.videoUploadFolder;
        videoFiles.push(videoFileToSend);
      }
    }

    if (attachmentId) {
      attachmentIds.push(attachmentId);
    }

    if (this.audioFileId) {
      attachmentIds.push(this.audioFileId);
    }

    if (attachmentIds.length || videoFiles.length) {
      const sendMsgCallback = () => {
        this.voiceRecordingVisible = false;
        this.audioFileId = null;
        this.audioUploaded = false;
        this.audioRecordingUrl = null;
        this.audioRecordingBlob = null;
      };

      dispatcher.dispatch('chat', 'sendMessagesAtOnce', this.thread, {
        message: message,
        replyTo: this.thread?.replyTo?.id,
        attachmentIds: attachmentIds,
        videoFiles: videoFiles,
        callback: sendMsgCallback,
      });
    } else {
      dispatcher.dispatch(
        'chat',
        'sendMessage',
        this.thread,
        {
          msg: message,
          newMessage: this.thread?.newMessage,
          replyTo: this.thread?.replyTo?.id,
        },
        false
      );
    }

    set(this, 'thread.newMessage', '');
    set(this, 'thread.replyTo', null);
    set(this, 'thread.mentions', A());

    this.lastNewMsg = '';
    this.unreadAmountOnInit = 0;
    this.typingTimer = false;

    if (this.editor) {
      this.editor.update('');
    }

    this.linkController.setInitialLinkProperties();

    PS.Pub('chat.message.sent', this.thread?.id);
  }

  @action
  toggleUserchatSize() {
    this.attachmentDropdownOpened = false;
    this.formattingDropdownOpened = false;

    PS.Pub('close.dropdowns.chat-options');

    // thread is minimized and clicked to maximize
    if (this.thread?.minimized) {
      // avoid calling messageRendered twice, already called while thread was minimized
      this.thread?.messages.forEach((m) => {
        if (m.isWs) m.set('isWs', false);
      });
      this.isMaximizing = true;

      // don't override 'Last read' position/button if it is already visible
      if (!this.showLastReadButtonAbove && this.thread?.newestMessagesShown) {
        const unread = this.thread?.unread,
          lastUnreadMsg = this.thread?.messages[unread];

        if (unread && this.thread?.newestMessagesShown) {
          this.hasScrolledToLastReadMessage = false;
          this.unreadAmountOnInit = unread;
          set(this, 'thread.lastReadMessageId', lastUnreadMsg ? lastUnreadMsg.id : ''); // needed for --new msgs-- border position
        }
      }

      later(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.isMaximizing = false;
          // checking last read msg position after maximizing is finished for proper result
          this.lastReadMsgIsAboveCurrentScroll = this.isLastReadMessageAboveView();
        }
      }, 150); //mw-chat transition time
    }
    // thread is maximized and clicked to minimize
    else {
      // do not reset 'Last read' button as it still holds proper position to scroll to
      if (!this.showLastReadButtonAbove && this.thread?.newestMessagesShown) {
        // minimizing & maximizing thread behaves the same way as closing & reopening:
        // --new msgs-- border will move to match the messages that arrive while minimized (same as first opening of 'unread' chat)
        this.newWsMsgsCount = 0; // minimizing/maximizing is a 'reset' action for newWsMsgsCount
        this.unreadAmountOnInit = 0;
        this.lastReadMsgIsAboveCurrentScroll = false;
      }
    }

    dispatcher.dispatch('chat', 'toggleMinimize', this.thread);

    if (this.thread?.chatFocused) {
      this.focusAndMarkAsRead();
    }
  }

  @action
  userAutocompleteFocus() {
    set(this, 'thread.chatFocused', true);
  }

  @action
  userAutocompleteBlur() {
    set(this, 'thread.chatFocused', false);
  }

  @action
  onTabKey(shift) {
    if (shift) {
      //focus previous if exist if not go to last
      let el = document.querySelector('.ql-editor[tabindex="' + (this.tabIndex - 1) + '"]');
      if (el) {
        el.focus();
      } else {
        el = document.querySelector('.ql-editor[tabindex="' + this.tabIndexOffset + '"]');
        if (el) {
          el.focus();
        }
      }
    } else {
      //focus next if exist if not go to first
      let el = document.querySelector('.ql-editor[tabindex="' + (this.tabIndex + 1) + '"]');
      if (el) {
        el.focus();
      } else {
        el = document.querySelector(
          '.ql-editor[tabindex="' + (this.tabIndexOffset - this.state.openedThreads?.length + 1) + '"]'
        );
        if (el) {
          el.focus();
        }
      }
    }
  }

  @action
  confirmLeavingChat() {
    const doLeave = () => {
      this.chat.transitionToThread(this.thread);
      this.closeSmallChat(true);

      // important to first do transition (above) and then set creationThread
      if (this.thread?.waitingForCreation) {
        ChatStore.getState().set('chatCreationThread', this.thread);
      }
    };

    if (this.audioRecordingBlob || this.audioFileId || this.isAudioRecording || this.isAudioConverting) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm navigation'),
        message: __(`You have unsent voice message. Are you sure you want to close this chat without sending?`),
        cancelButtonText: __('Stay'),
        okButtonText: __('Leave'),
        onConfirm: () => {
          doLeave();
        },
        allowMultipleInstances: false,
      });
    } else {
      doLeave();
    }
  }

  @action
  toggleCallDropdown(value) {
    this.chatCallDropdownOpened = value;

    if (this.chatCallDropdownOpened) {
      if (!this.thread?.chatFocused) {
        dispatcher.dispatch('chat', 'focusChat', this.thread);
      }

      this.blockReportDropdownOpened = false;
    }
  }

  @action
  setAttachmentDropdown(isOpened) {
    this.attachmentDropdownOpened = isOpened;
  }

  @action
  setEditor(editor) {
    this.editor = editor;
  }
}
