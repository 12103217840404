import { computed } from '@ember/object';
import { A } from '@ember/array';
import { bind } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import EnvUtils from 'mewe/utils/environment-utils';
import layout from './template.hbs';
import './styles.scss';
import { withLabels_Contacts, withLabels_Group, withLabels_GroupOwnerAdmins } from './utils';
import { scheduleOnce } from '@ember/runloop';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isNumber } from 'lodash';
import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';

const MINIMUM_AUTOCOMPLETE_WIDTH = 200;

export default class AutocompleteDropdown extends PopupOpener {
  layout = layout;

  @service account;

  _clickOutsideListener = null;
  keyboardListElementSelector = 'li';
  keyboardListElementActionName = 'selectItem';
  popupType = 'autocomplete';
  dropdownId = 'autocomplete-dropdown';

  @tracked keyboardListActiveIndex = -1;
  @tracked keyboardListItems = A([]);
  @tracked elementOffsetHeight = 0;
  @tracked elementOffsetWidth = 0;
  @tracked loading = false;
  @tracked offset = 0;
  @tracked position;
  @tracked term;
  @tracked dropdownOpened = false;

  constructor() {
    super(...arguments);
    this.position = this.args.params.position;
    this.term = this.args.params.term;
    addObserver(this, 'term', this.track);
    addObserver(this, 'keyboardListItems.length', this.visibilityObserver);
  }

  willDestroy() {
    removeObserver(this, 'term', this.track);
    removeObserver(this, 'keyboardListItems.length', this.visibilityObserver);
  }

  keyDown(e) {
    if (e.key === 'ArrowUp' || e.code === 'ArrowUp' || e.keyCode === 38) {
      // arrow up
      this.onArrowUp();

      e.preventDefault();
      return false;
    } else if (e.key === 'ArrowDown' || e.code === 'ArrowDown' || e.keyCode === 40) {
      // arrow down
      this.onArrowDown();

      e.preventDefault();
      return false;
    } else if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      // enter
      let action = this.keyboardListElementActionName;

      if (action) {
        let selected = this.keyboardListItems[this.keyboardListActiveIndex];
        if (selected) {
          this[action](selected, this.keyboardListActiveIndex);
        }

        e.preventDefault();
        return false;
      }
    } else {
      this.resetKeyboardList();
    }
  }

  resetKeyboardList() {
    this.keyboardListActiveIndex = -1;
  }

  scrollIndexIntoView(index) {
    if (!index) return;
    const elem = this.element.querySelectorAll(this.keyboardListElementSelector)[index];

    if (!elem) return;

    elem.scrollIntoView({
      block: 'center',
    });
  }

  getActiveListItemIndex() {
    let index = this.keyboardListActiveIndex;

    if (!isNumber(index)) return -1;

    return index;
  }

  onArrowUp() {
    this.setNeighborAsActiveListElement(false);
  }

  onArrowDown() {
    this.setNeighborAsActiveListElement(true);
  }

  setNeighborAsActiveListElement(setToNext) {
    let index = this.getActiveListItemIndex();

    if (setToNext) {
      index += 1;
      if (index >= this.keyboardListItems.length) index = this.keyboardListItems.length - 1;
    } else {
      index -= 1;
      if (index < 0) index = 0;
    }

    this.keyboardListActiveIndex = index;
    this.scrollIndexIntoView(index);
  }

  @computed('strategy')
  get _strategy() {
    return this.args.params.strategy.strategy || this.args.params.strategy;
  }

  get strategy() {
    return this._strategy;
  }

  // we show information when user enter @ - but only for contacts
  @computed('strategy', 'term')
  get showContactsEmptyInfo() {
    const strategy = this._strategy;
    return strategy.scope == 'contacts' && (this.term == '@' || this.term == '');
  }

  search(term, offset) {
    const strategy = this._strategy;

    strategy.search(
      term,
      (data) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        next(() => {
          if (!offset) {
            this.keyboardListItems = A();
            this.keyboardListActiveIndex = 0;
          }

          this.keyboardListItems.pushObjects(data);
        });
      },
      offset
    );
  }

  get itemsMapped() {
    let items = this.keyboardListItems;
    const strategy = this._strategy;

    if (strategy.id === 'emoji') {
      const mapped = items.map((el) => this._strategy.template(el));
      return mapped;
    }

    if (strategy.id === 'hashtag') {
      const mapped = items.map((el) => this._strategy.template(el));
      return mapped;
    }

    if (strategy.scope == 'contacts') {
      items = items && withLabels_Contacts(items);
    }

    if (strategy?.scope?.groupId) {
      if (this.term == '' || this.term == '@') items = withLabels_GroupOwnerAdmins(items, this.args.params.groupName);
      else items = withLabels_Group(items, this.args.params.groupName);
    }

    const mapped = items.map((mention) => {
      let links = mention._links || mention.user._links;
      let avatarUrl = '';

      if (links) {
        avatarUrl = EnvUtils.getImgHost(true) + links.avatar.href.replace('{imageSize}', '150x150');
      } else {
        avatarUrl = EnvUtils.getImgHost(true) + '/api/v2/photo/profile/150x150/' + mention.user.id;
      }

      mention.avatarUrl = avatarUrl;

      return mention;
    });

    return mapped;
  }

  @action
  track() {
    this.search(this.term.length ? this.term.substr(1) : '');
  }

  setDefaultValues() {
    this.loading = false;
    this.offset = 0;
    this.keyboardListItems = A([]);
    this.keyboardListElementActionName = 'selectItem';

    this.resetKeyboardList();
  }

  @computed('args.params.autocompletePlacement', 'dropdownOpened', 'position.{left,top}', 'keyboardListItems.length', 'elementOffsetHeight', 'elementOffsetWidth')
  get style() {
    const placeOnLeft = window.innerWidth - this.position.left - MINIMUM_AUTOCOMPLETE_WIDTH < 0;

      const styleHorizontal = placeOnLeft
        ? `left: ${Math.floor(this.position.left - this.elementOffsetWidth)}px;`
      : `left: ${Math.floor(this.position.left)}px;`;

    const offset = this.elementOffsetHeight;
    let styleVertical = '';

      if (this.args.params.autocompleteFixedPosition) {
        // top positioning here, no usecase for bottom so far
        styleVertical = `position: fixed; top: ${Math.floor(this.position.top - offset - window.scrollY)}px;`;
      } else {
        styleVertical =
          this.args.params.autocompletePlacement === 'top'
            ? `top: ${Math.floor(this.position.top - offset)}px;`
            : `top: ${Math.floor(this.position.top + 30)}px;`;
      }

      const display = this.dropdownOpened ? `display: block;` : `display: hidden;`;

    return htmlSafe(styleVertical + styleHorizontal + display);
  }

  clickOutside(parentElement, event) {
    if (!parentElement.contains(event.target)) {
      this.closeAndDestroyDropdown();
      this.args.params.onClose();
    }
  }

  closeAndDestroyDropdown() {
    document.body.removeEventListener('click', this._clickOutsideListener);
  }

  @action
  visibilityObserver() {
    scheduleOnce('afterRender', this, () => {
      this.elementOffsetHeight = this.element.offsetHeight;
      this.elementOffsetWidth = this.element.offsetWidth;
    });

    this.args.params.visibilityChange(!!this.keyboardListItems.length);
  }

  @action
  selectItem(selected, i) {
    const list =
      this._strategy.id === 'hashtag' || this._strategy.id === 'emoji' ? this.keyboardListItems : this.itemsMapped;
    this.args.params.onSelect(list[i], this.args.params.strategy, this.term);
    this.closeAndDestroyDropdown();
  }

  @action
  insertElement(e) {
    this.element = e;
    this.keyboardListActiveIndex = 0;
    this.elementOffsetHeight = 0;
    this.elementOffsetWidth = 0;
    this._clickOutsideListener = bind(this, this.clickOutside, this.element);
    document.body.addEventListener('click', this._clickOutsideListener);
    this.search(this.term);
  }
}
