import Component from '@glimmer/component';
import GroupApi from 'mewe/api/group-api';
import Session from 'mewe/shared/session';
import { inject as service } from '@ember/service';

export default class ConfirmGroupInvite extends Component {
  @service router;

  constructor() {
    super(...arguments);
    this.processInvitation();
  }

  processInvitation() {
    if (!this.args.groupId || !this.args.invitationId) {
      this.router.transitionTo('register');
      return;
    }

    // apparently this API returns invitation data only for invitations for non registered users,
    // otherwise it fails for all types of gruops (that's what I saw when tested). In this case I
    // treat failures as info that user exists and while he's invited we can direct him to group after login
    // - second param is TRUE -> we only log user in and redirect to group and don't auto merge invitation
    //   because there can be group questions to anser first, user will do that inside the app
    GroupApi.confirmGroupEmailInvitation(this.args.invitationId, true)
      .then((data) => this.groupInvitationConfirmation(data))
      .catch(() => this.checkAuthenticationAndRedirect());
  }

  groupInvitationConfirmation(data) {
    // if user is already logged in, we just need to redirect him
    if (data.confirmed) {
      this.redirectToGroup();
    } else {
      this.processGroupConfirmation(data);
    }
  }

  redirectToGroup() {
    window.location.href = `/group/${this.args.groupId}?previewContext=email`;
  }

  processGroupConfirmation(data) {
    if (data.needRegister) {
      const queryParams = { email: data.email };

      if (this.args.invitationId) {
        queryParams.inId = this.args.invitationId;
      }

      if (data.groupPublicUrlId) {
        // move to group public page only if public group
        return this.router.transitionTo('join-group', data.groupPublicUrlId, { queryParams: queryParams });
      } else {
        queryParams.gpId = `id-${this.args.groupId}`;
        // move to homepage registration if private group
        return this.router.transitionTo('register', {
          queryParams: queryParams,
        });
      }
    } else {
      this.checkAuthenticationAndRedirect();
    }
  }

  checkAuthenticationAndRedirect() {
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      if (isAuthenticated) {
        this.redirectToGroup();
      } else {
        // if noone is logged in we let user to log in and then go to group,
        // `id-` prefix indicates that it's regular group id and not public group id
        if (this.args.groupId) {
          return this.router.transitionTo('login', { queryParams: { gpId: `id-${this.args.groupId}` } });
        } else {
          // fallback just in case - this shouldn't get called:
          return this.router.transitionTo('register');
        }
      }
    });
  }
}
