/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import toDisplay from 'mewe/stores/text-parsers/to-display';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class GenericNotification extends Component {
  @service router;
  @service analytics;
  @service account;
  @service dynamicDialogs;

  get textDisplay() {
    return toDisplay(this.args.notification.subtitle);
  }

  get imagePath() {
    if (!this.args.notification.imagePath) {
      return null;
    }

    // e.g. /front/static/dsnp/notifications/notification_flowers.png is given for Frequency claiming notification
    // but /api/v2/photo/D2fgw4uSC5FFG4W9hfWKOlAmpWdO6DyVYfM_jSgQblpnry8SCaZ7PndiqRI/{imageSize}/img?static={static} for other generic notification
    // so I believe that the first one is a legacy before when we created admin panel for managing images for generic notifications
    const imagePath = this.args.notification.imagePath;
    const host = ~imagePath.indexOf('/front/static/') ? EnvironmentUtils.getCdnHost() : EnvironmentUtils.getImgHost(true);
    return host + imagePath;
  }

  @action
  genericNotificationClick(n) {
    this.args.markVisited(n);
  }

  @action
  genericNotificationActionClick(n) {
    this.args.markVisited(n);

    if (n.callToActionUrl) {
      window.open(n.callToActionUrl, '_blank');
      return;
    }

    if (n.notificationSubtype === 'dsnp_migration') {
      if (this.account.activeUser.dsnpHandle) {
        this.dynamicDialogs.openDialog('simple-dialog-new', {
          closeBtnText: __(`Exit`),
          message: __(
            `Congratulations! You have already migrated your account to web3.<br><br>There is nothing else you need to do.`
          ),
          noEscaping: true,
        });
      } else {
        this.router.transitionTo('web3-migrate');
      }

      this.analytics.sendEvent('notificationCenterNotificationClicked', {
        notification_type: n.notificationType,
        notification_subtype: n.notificationSubtype,
        notification_variant: n.notificationVariant,
      });
    }
  }
}
