import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import layout from './template.hbs';
import './styles.scss';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwSortDropdownComponent extends PopupOpener {
  @service dynamicDialogs;

  layout = layout;
  popupType = 'dropdown-popup';
  closeOnClickOutside = true;
  dontCloseAt = '.dropdown--dont-close';

  get dropdownPosition() {
    let isRtl = document.dir;
    if (this.args.alignRight) {
      isRtl = !isRtl;
    }

    let position = this.getDefaultDropdownOptions(true);

    Object.assign(position, {
      reposition: { elementHeight: 180 },
      placement: {
        top: false,
        left: isRtl,
      },
      translate: { x: this.args.translateX || -20 },
    });

    return position;
  }
}
