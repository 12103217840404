import EmberObject from '@ember/object';

// returns time from seconds to hh:mm:ss
export const getDurationTimeFromSeconds = function (durationTime) {
  if (!Number.isFinite(durationTime)) return;
  var hours = Math.floor(durationTime / 3600);
  var minutes = Math.floor((durationTime - hours * 3600) / 60);
  var seconds = Math.floor(durationTime - hours * 3600 - minutes * 60);

  if (hours === 0) {
    hours = '';
  } else if (hours < 10) {
    hours = '0' + hours + ':';
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return hours + minutes + ':' + seconds;
};

export const recordingAnimationApi = [
  {
    path: {
      value0: EmberObject.create({ value: 250, grow: true }),
      value1: EmberObject.create({ value: 350, grow: false }),
      value2: EmberObject.create({ value: 250, grow: true }),
    },
    strokeWidth: 3,
    v: 4.6,
    min: 255,
    max: 345,
  },
  {
    path: {
      value0: EmberObject.create({ value: 260, grow: true }),
      value1: EmberObject.create({ value: 340, grow: false }),
      value2: EmberObject.create({ value: 260, grow: true }),
    },
    strokeWidth: 1,
    v: 4,
    min: 260,
    max: 340,
  },
  {
    path: {
      value0: EmberObject.create({ value: 270, grow: true }),
      value1: EmberObject.create({ value: 330, grow: false }),
      value2: EmberObject.create({ value: 270, grow: true }),
    },
    strokeWidth: 1,
    v: 3,
    min: 270,
    max: 330,
  },
  {
    path: {
      value0: EmberObject.create({ value: 280, grow: true }),
      value1: EmberObject.create({ value: 320, grow: false }),
      value2: EmberObject.create({ value: 280, grow: true }),
    },
    strokeWidth: 1,
    v: 2,
    min: 280,
    max: 320,
  },
  {
    path: {
      value0: EmberObject.create({ value: 290, grow: true }),
      value1: EmberObject.create({ value: 310, grow: false }),
      value2: EmberObject.create({ value: 290, grow: true }),
    },
    strokeWidth: 1,
    v: 1,
    min: 290,
    max: 310,
  },
];

export const getSpotifyId = function (url) {
  const match = url.match(/^https:\/\/open\.spotify\.com\/track\/([a-zA-Z0-9]+)/);
  return match ? match[1] : null;
};
