import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next, scheduleOnce, bind, later, cancel } from '@ember/runloop';
import * as Sentry from '@sentry/ember';
import PS from 'mewe/utils/pubsub';
import { repositionSimpleDropdown } from 'mewe/utils/popup-utils';
import { getElWidth, getElHeight } from 'mewe/utils/elements-utils';

export default class PopupOpener extends Component {
  @tracked parent = this.args.parent ?? this.args.params?.parent;

  @tracked displayPopup = this.delayUntilOpen ? false : true;

  @tracked popupOnTop = false;

  element;

  openingTimeout;

  openClass = 'popup-is-opened';

  popupType = 'dropdown-popup';

  dontCloseAt = '.dropdown--dont-close';

  constructor() {
    super(...arguments);
    this.closeBinded = this._close.bind(this);
    PS.Sub('close.dropdowns', this.closeBinded);
    this.args.params?.proxy?.setPopup?.(this);
  }

  willDestroy() {
    this._close();
    super.willDestroy(...arguments);
  }

  get dropdownStyle() {
    if (this.isDestroyed || this.isDestroying || this.popupType !== 'dropdown-popup') {
      return undefined;
    }

    return repositionSimpleDropdown(this.dropdownPosition);
  }

  get isTopPopup() {
    return this.dropdownStyle?.placement?.top;
  }

  get dropdownId() {
    return this._dropdownId || this.args.dropdownId || this.args.params?.dropdownId;
  }

  //to override in popups
  getPlacement() {
    return null;
  }

  position() {
    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      let placement = this.getPlacement();
      if (placement && this.element) {
        this.element.style.top = `${placement.top}px`;
        this.element.style.left = `${placement.left}px`;
        this.element.style.right = `${placement.right}px`;

        // used for mw-emoji-list-popup
        if (placement.marginRight) {
          this.element.style.marginRight = `${placement.marginRight}px`;
        }
      }

      // reveal element after positioning
      next(() => {
        this.element.classList.remove('d-none');
      });
    });
  }

  // includeWindowScroll if parent is the body and scrolls
  getDefaultDropdownOptions(includeWindowScroll) {
    // missing parent seems to cause errors in sentry, trying to verify that (https://exceptions.mewe.com/organizations/mewe/issues/1423)
    if (!this.parent) {
      Sentry.captureException(new Error('getDefaultDropdownOptions: ' + this.classNames));
      this.parent = document.body;
    }

    const parent = this.parent;
    const position = parent.getBoundingClientRect();
    return {
      margin: 15,
      parentOffset: {
        top: includeWindowScroll ? position.top + window.scrollY : position.top,
        left: position.left,
      },
      parentSize: {
        width: getElWidth(parent),
        height: getElHeight(parent),
      },
    };
  }

  mouseMoveEvent(event) {
    // 'mouseMoveWrapper' allows to set different element as area for mouse move than
    // 'parent' which can be treated as dropdown positioning element
    const mouseMoveWrapper = this.mouseMoveWrapper || this.parent;
    const target = event.target;
    const popup = this.element;

    if (this.parent.contains(target)) {
      return;
    }

    if (!mouseMoveWrapper) {
      return;
    }

    if (mouseMoveWrapper !== target && !mouseMoveWrapper.contains(target)) {
      // if popupTimeout is set then popup is waiting to be opened
      // in that case don't check if target is contained by popup element
      // we want target to be only inside mouseMoveWrapper element for opening with timeout
      if (this.popupTimeout) {
        this._close();
      } else if (popup && target != popup && !popup.contains(target)) {
        // don't close this popup on hovering over another popup by adding
        // dontCloseAt class to that child popup - SG-25288/SG-40275
        if (target.closest(this.dontCloseAt)) {
          return;
        }
        this._close();
      }
    }
  }

  setMouseMoveEvent() {
    this._mouseMoveEventListener = bind(this, this.mouseMoveEvent);
    document.body.addEventListener('mousemove', this._mouseMoveEventListener);
  }

  keyDownEvent(e) {
    const KEY_ESC = 27,
      KEY_TAB = 9;
    if (e.keyCode === KEY_ESC || e.keyCode === KEY_TAB) {
      this._close();
    }
  }

  setClickOutsideCloseEvent() {
    this.clickOutsideEventBind = this.clickOutsideEvent.bind(this);
    document.addEventListener('click', this.clickOutsideEventBind);
  }

  clickOutsideEvent(event) {
    if (event.target.closest && event.target.closest(this.dontCloseAt)) {
      return;
    }
    this._close();
  }

  setKeyPressCloseEvent() {
    if (this.closeOnKeyPress) {
      this.keyDownEventBind = this.keyDownEvent.bind(this);
      document.body.addEventListener('keydown', this.keyDownEventBind);
    }
  }

  mouseLeaveEvent(event) {
    if (event.target.closest && event.target.closest(this.dontCloseAt)) {
      return;
    }
    this._close();
  }

  setDocumentMouseLeaveEvent() {
    this.mouseLeaveEventBind = this.mouseLeaveEvent.bind(this);
    document.addEventListener('mouseleave', this.mouseLeaveEventBind);
  }

  beforeCloseWrapper() {
    this.beforeCloseBind = this.beforeClose.bind(this);
    document.addEventListener('mouseup', this.beforeCloseBind);
  }

  beforeClose(event) {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (event.type === 'mouseup') {
      const clickedInsideDropdownCloseArea =
        event.target.closest('.dropdown--close') || !event.target.closest(`#${this.element?.id}`);
      const clickedOnAllowToCloseElement = !event.target.closest(this.dontCloseAt);

      const canClose = clickedInsideDropdownCloseArea && clickedOnAllowToCloseElement;

      if (canClose) {
        document.removeEventListener('mouseup', this.beforeCloseBind);

        next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          this._close();
        });
      }
    }
  }

  setDropdownPopupCloseEvent() {
    this.beforeCloseWrapperBind = this.beforeCloseWrapper.bind(this);
    document.addEventListener('mousedown', this.beforeCloseWrapperBind);
  }

  scrollEvent(e) {
    let scrolledInsideDropdownCloseArea;

    if (e.target.closest) {
      scrolledInsideDropdownCloseArea = e.target.closest(`#${this.element?.id}`);
    }

    // insideAnotherScrollable - prevent scrolling whole page when scrolling over popup element, happens because popup is placed in main page container
    // useful when popup is inside another scrollable element and scrolling moves whole page in the background instead of scrollable element SG-17198
    if (!scrolledInsideDropdownCloseArea || this.insideAnotherScrollable || this.args.params.insideAnotherScrollable) {
      this._close();
    }
  }

  setScrollCloseEvent() {
    this.scrollEventBind = this.scrollEvent.bind(this);

    // 1. closeOnScroll - close popup on any scroll, usually whole window scroll
    if (this.closeOnScroll) {
      window.addEventListener('scroll', this.scrollEventBind);
    }

    // 2. close popup when its parent is contained in specific class (popup-scroll-close),
    // it's useful when popup is inside another scrollable element and scrolling moves that element instead of whole page
    // but popup stays in place as fixed to position on page.
    this.scrollWrapper = this.parent.closest('.popup-scroll-close');
    this.scrollWrapper?.addEventListener('scroll', this.scrollEventBind);
  }

  isPopupToClose() {
    return (
      this.popupType === 'dropdown-popup' ||
      this.popupType === 'giphy-popup' ||
      this.popupType === 'simple-emoji-picker'
    );
  }

  @action
  _open() {
    if (document.getElementsByClassName(this.popupType).length > 0) {
      return;
    }

    // display actuall content of popup - content has to be wrapped in {{#if this.dispalyPopup}} condition
    // or a hidden class based on this flag can be used to hide popup content. It's used for dealyed opening
    this.displayPopup = true;
    this.clearOpeninigTimeout();

    this.element = document.getElementById(this.dropdownId);

    if (!this.element) {
      this._close();
      return;
    }

    this.setKeyPressCloseEvent();
    this.setScrollCloseEvent();

    if (this.args.closeOnClickOutside) {
      this.setClickOutsideCloseEvent();
    }

    if (this.closeOnHoverOutside) {
      this.setMouseMoveEvent();
      this.setDocumentMouseLeaveEvent();
    }

    if (this.popupType !== 'dropdown-popup') {
      this.position();
    }

    // some popups might be hidden before setting location especially when they contains autofocus
    // if (this.element) {
    //   this.element.classList.remove('hidden');
    // }

    // rework after complete transition to plain JS
    if (this.parent) {
      if (this.parent instanceof Element) {
        if (Array.isArray(this.openClass)) {
          this.parent.classList.add(...this.openClass);
        } else {
          this.parent.classList.add(this.openClass);
        }
      } else {
        this.parent.addClass(this.openClass);
      }
    }

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (typeof this.onOpen === 'function') {
        this.onOpen(this.popupId);
      }

      if (this.isPopupToClose()) {
        next(() => {
          this.setDropdownPopupCloseEvent();
        });
      }
    });
  }

  _close() {
    // rework after complete transition to plain JS
    if (this.parent) {
      if (this.parent instanceof Element) {
        this.parent.classList.remove(this.openClass);
      } else {
        this.parent.removeClass(this.openClass);
      }
    }
    if (this.closeOnKeyPress) {
      document.body.removeEventListener('keydown', this.keyDownEventBind);
    }
    if (this.closeOnHoverOutside) {
      document.body.removeEventListener('mousemove', this._mouseMoveEventListener);
      document.removeEventListener('mouseleave', this.mouseLeaveEventBind);
    }
    if (this.closeOnScroll) {
      window.removeEventListener('scroll', this.scrollEventBind);
    }
    if (this.scrollWrapper) {
      this.scrollWrapper.removeEventListener('scroll', this.scrollEventBind);
    }
    if (this.isPopupToClose()) {
      document.removeEventListener('mousedown', this.beforeCloseWrapperBind);
    }
    if (this.args.closeOnClickOutside) {
      document.removeEventListener('click', this.clickOutsideEventBind);
    }

    PS.Unsub('close.dropdowns', this.closeBinded);

    if (this.args.onClose) {
      this.args.onClose();
    } else {
      this.showPopup = false;
    }
  }

  @action
  setupPopup() {
    if (this.delayUntilOpen) {
      this.openingTimeout = later(this._open, this.delayUntilOpen);

      this.clearOpeninigTimeoutBind = this.clearOpeninigTimeout.bind(this);
      this.parent.addEventListener('mouseleave', this.clearOpeninigTimeoutBind);
    } else {
      this._open();
    }
  }

  clearOpeninigTimeout() {
    this.parent.removeEventListener('mouseleave', this.clearOpeninigTimeoutBind);
    cancel(this.openingTimeout);
    this.openingTimeout = null;

    // if popup was not opened yet then close it (to clear the state)
    // if popup was already opened then this method is called on opening to clear timeout/listener only
    if (!this.displayPopup) {
      this._close();
    }
  }

  @action
  close() {
    this._close();
  }
}
