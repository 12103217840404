import { A } from '@ember/array';
import { set } from '@ember/object';
import { default as fuHelper } from 'mewe/utils/fileupload-utils';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import PostCommons from 'mewe/utils/posting-utils';
import toServer from 'mewe/stores/text-parsers/to-server';
import baseController from './baseController';
import config from 'mewe/config';
import {
  photoMaxWeight,
  photoMaxWidthHeight,
  photoMaxWeightToResizeInBrowser,
  photoMaxWeightToUploadToServer,
  prettyWeight as weight,
  hasBigPhoto,
} from 'mewe/constants';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import Session from 'mewe/shared/session';
import { resizeImage } from 'mewe/utils/fileupload-utils';
import axios from 'axios';
import Verbose from 'mewe/utils/verbose';
import isUndefined from 'mewe/utils/isUndefined';
import PS from 'mewe/utils/pubsub';

const verbose = Verbose({ prefix: '[Postbox/photo]' }).log;
const CancelToken = axios.CancelToken;

class Photo extends baseController {
  constructor(postbox) {
    super(...arguments);
    this.postbox = postbox;
    this.setInitialAttributes();
  }

  handlePhoto({ files }) {
    if (this.postbox.sticker || this.postbox.pickers.stickPickerIsOpen(this.postbox)) return;

    const resizeQueue = [];

    files.forEach((originalFile) => {
      if (!originalFile.uploadId) console.error('postbox photo: uploadId is missing');

      if (fuHelper.isDestroying(this.postbox)) return;

      if (this.storageAlertShown) this.storageAlertShown = false;

      // photo is quite big, inform user
      if (hasBigPhoto(files)) this.postbox.photoIsBig = true;

      // photo is too big in weight to upload without resizing
      if (originalFile.size >= photoMaxWeightToUploadToServer) {
        // data.imageQuality = 0.6;
        verbose('photo will be resized because of weight...');
      }

      if (fuHelper.isNonImage(this.postbox, originalFile)) return;

      // check max photos limit
      if (this.photosToUploadCount + this.uploadedPhotosCount >= config.maxFileLimit) {
        PS.Pub('open.generic.dialog', {
          title: __('Photo upload error'),
          message: __('Sorry, you can only upload up to {limit} pictures at a time.', { limit: config.maxFileLimit }),
        });
        return;
      }

      this.photosToUploadCount++;

      verbose(`weight of file before process: ${weight(originalFile.size)}`);

      this.previewSubmitData(originalFile);

      resizeQueue.push(originalFile);
    });

    setTimeout(() => {
      const uploadQueue = resizeQueue.reduce((p, originalFile) => {
        return p.then((results) => {
          // don't process - resize gifs, because of bug #SG-8352
          if (
            originalFile.type.indexOf('image/gif') !== -1 ||
            originalFile.size < photoMaxWeight ||
            originalFile.size > photoMaxWeightToResizeInBrowser
          ) {
            results.push({ id: originalFile.uploadId, file: originalFile });

            return Promise.resolve(results);
          } else {
            return resizeImage(originalFile, {
              maxWidth: photoMaxWidthHeight,
              maxHeight: photoMaxWidthHeight,
            }).then((file) => {
              if (file.size == originalFile.size) {
                verbose(`weight of photo didn't change after process`);
              } else {
                verbose(`weight of file after process: ${weight(file.size)}`);
              }

              // photo after resizing it still too big to upload to server
              if (file.size >= photoMaxWeightToUploadToServer) {
                PS.Pub('open.generic.dialog', {
                  title: __('Photo upload error'),
                  message: __('Photo should not exceed {limit}', { limit: weight(photoMaxWeightToUploadToServer) }),
                });
                return;
              }

              results.push({ id: originalFile.uploadId, file: file });

              return results;
            });
          }
        });
      }, Promise.resolve([]));

      uploadQueue.then((objs) => {
        objs.forEach((obj) => this.uploadPhoto(obj.id, obj.file));
      });

      this.postbox.recalculatePostboxScroll();
    }, 0);
  }

  uploadPhoto(uploadId, file) {
    let formData = new FormData();
    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url: EnvironmentUtils.getApiHost() + '/api/v2/photo/pt',
      method: 'POST',
      headers: { 'X-CSRF-Token': Session.getCsrfToken() },
      cancelToken: source.token,
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          const photos = this.postbox.photo.photos;

          if (photos && photos.length) {
            let tempPhoto = photos.find((el) => el.tempId == uploadId);

            if (tempPhoto) {
              const progress = parseInt((data.loaded / data.total) * 100, 10);
              set(tempPhoto, 'progress', progress);
            }
          }
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(uploadId, res.data);
      })
      .catch((e) => {
        this.fail(uploadId, e.response);
      })
      .then(() => {
        // if in case that processing of image would be faster then rendering it, take care of after-cleanup here
        this.uploadDidFinish();
      });

    this.jqXHRs[uploadId] = source;
  }

  done(uploadId, data) {
    let tempPhoto = this.postbox.photo.photos?.find((el) => el.tempId == uploadId);

    // the real id is assigned here
    if (data.id) {
      // always keep track of information about photoBoxId->id pairings
      this.photoBoxIdPhotoIdPairs[data.tempId] = data.id;

      if (tempPhoto) {
        set(tempPhoto, 'id', data.id);
        set(tempPhoto, 'loading', false);
        set(tempPhoto, 'type', data.type);

        delete this.jqXHRs[tempPhoto.tempId];
      }

      this.uploadedPhotosCount++;
      this.setPhotoInProgress();
    }
  }

  fail(uploadId, response = {}) {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    if (response.status == 401) return;

    if (response.data) {
      if (response.data.errorCode === 700) {
        if (!this.storageAlertShown) {
          this.storageAlertShown = true;
          this.postbox.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
          this.removePhoto(response.data);
        }
        this.abortPendingUploads();

        return;
      } else if (response.data.errorCode === 703) {
        FunctionalUtils.error(__('Sorry, resolution of this photo is too big'));
        this.removePhoto(response.data);

        return;
      }
    }

    let retries = this.retries[uploadId] || 0;

    if (response.data && retries < this.maxRetries) {
      clearTimeout(this.retryTimeouts[uploadId]);
      this.retryTimeouts[uploadId] = setTimeout(function () {
        // sentry issue: "response.data.submit is not a function" (issues/3348504841)
        if (typeof response.data.submit === 'function') {
          response.data.submit();
        }
      }, this.retryTimeoutValues[retries]); // progressive timeout
      this.retries[uploadId] = retries + 1;
    } else {
      let photo = this.postbox.photo.photos?.find((el) => el.tempId == uploadId);

      if (photo) {
        set(photo, 'failedToLoad', true);
        set(photo, 'requestToRetry', response.data);
        if (this.photosToUploadCount > 0) this.photosToUploadCount--;
      }
    }
  }

  setInitialAttributes() {
    this.postType = 'photo';
    this.acceptVideoFileTypes = /^video\/.*$/;
    this.photosToUploadCount = 0;
    this.uploadedPhotosCount = this.postbox.args.preselectedPhotos?.length || 0;
    this.photoBoxIdPhotoIdPairs = {};

    this.retries = {};
    this.maxRetries = 6;
    this.retryTimeouts = {};
    this.retryTimeoutValues = [1000, 3000, 5000, 7000, 8000, 10000];
    this.jqXHRs = {};
  }

  afterRenderPhoto(photo) {
    this.postbox.photo.photos?.pushObject(photo);
    this.setPhotoInProgress();
    this.updateGlobalProgress();
    this.postbox.updateHeight();
  }

  setPhotoInProgress() {
    if (this.uploadedPhotosCount < this.photosToUploadCount) {
      let nextPhotoToUpload;

      const photoTemp = (photo) => {
        return photo.tempId === prop;
      };

      // find next image to upload. List is sortable so after every file it's finding first not uploaded image on the list
      for (var prop in this.jqXHRs) {
        nextPhotoToUpload = this.postbox.photo.photos?.find(photoTemp);
        break;
      }

      if (nextPhotoToUpload) {
        set(nextPhotoToUpload, 'loading', true);
      }
    }
  }

  updateGlobalProgress() {
    const photos = this.postbox.photo.photos;
    this.postbox.photo.loading = photos.some((p) => p.loading);
  }

  uploadDidFinish() {
    if (this.postbox.isDestroying || this.postbox.isDestroyed) {
      return;
    }

    this.postbox.photo.photos?.forEach((elem) => {
      if (this.photoBoxIdPhotoIdPairs[elem.tempId]) {
        // for each photo that was not updated, do it now
        set(elem, 'id', this.photoBoxIdPhotoIdPairs[elem.tempId]);
      }
    });

    this.postbox.photoIsBig = hasBigPhoto(this.postbox.photo.photos);
    this.updateGlobalProgress();
  }

  removePhoto(photo) {
    const postbox = this.postbox;

    // decrease counter only if photo has id - it means it was completely loaded
    if (photo.id && this.uploadedPhotosCount > 0) this.uploadedPhotosCount--;

    if (this.photosToUploadCount > 0) this.photosToUploadCount--;

    Object.keys(this.jqXHRs).forEach((key) => {
      if (key === photo.tempId) {
        this.jqXHRs[key].cancel();
        delete this.jqXHRs[photo.tempId];
        this.setPhotoInProgress();
      }
    });

    clearTimeout(this.retryTimeouts[photo.tempId]);
    set(photo, 'failedToLoad', true);

    const photoToRemove = postbox.photo.photos?.find((p) => p.tempId === photo.tempId);

    postbox.photo.photos?.removeObject(photoToRemove);
    postbox.photoIsBig = hasBigPhoto(this.postbox.photo.photos);

    postbox.uploadService.remove(photo.tempId);
    this.updateGlobalProgress();
    postbox.updateHeight();
  }

  shareController(params) {
    if (this.postbox.isSharing) return;

    // some photos may failed during upload, filtering them out
    let photos = this.postbox.photo.photos?.filter((p) => {
      return !isUndefined(p.id);
    });

    const modifiedPhotos = photos
      .filter((fl) => fl.caption)
      .map((fl) => ({
        id: fl.id,
        caption: toServer(fl.caption, { parseNativeMarkdown: true }),
      }));

    const existingMedias = this.postbox.photo.photos.filter((p) => !isUndefined(p.existing)).map((p) => p.mediaId);

    const imageIds = photos.filter((p) => isUndefined(p.existing)).map((p) => p.id);

    const options = PostCommons.getStandardPostingOptions(
      Object.assign(
        {},
        {
          text: this.postbox.newPostServer,

          album: this.postbox.selectedAlbumName || null,

          photos: modifiedPhotos.length ? modifiedPhotos : null,

          mediaIds: existingMedias,

          imageIds: imageIds,
        },
        params
      ),
      this.postbox
    );

    PostCommons.share(options, this.postbox);
  }

  abortPendingUploads() {
    Object.keys(this.jqXHRs).forEach((key) => {
      this.jqXHRs[key].cancel();

      let photo = this.postbox.photo.photos?.find((el) => el.tempId == key);

      if (photo) {
        this.postbox.photo.photos?.removeObject(photo);
        set(photo, 'failedToLoad', true);
      }

      if (this.photosToUploadCount > 0) this.photosToUploadCount--;
    });

    this.jqXHRs = {};
  }

  isEmpty() {
    return this.postbox.photo.photos?.length < 1;
  }
}

export default Photo;
