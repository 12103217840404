// import 'mewe/templates/application';
import { getOwner } from '@ember/application';
import EmberObject from '@ember/object';
import Route from 'mewe/routes/route';
import Session from 'mewe/shared/session';
import browserClasses from 'mewe/utils/browser-classes';
import { setTheme } from 'mewe/utils/miscellaneous-utils';
import { hasAnyThemeClass } from 'mewe/utils/color-utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import PS from 'mewe/utils/pubsub';
import { getConfigCatFlagPromise } from 'mewe/shared/config-cat';
import Verbose from 'mewe/utils/verbose';
const verbose = Verbose({ prefix: '[Application Route]' }).log;

export default class ApplicationRoute extends Route {
  @service router;
  @service settings;
  @service account;
  @service analytics;

  constructor() {
    super(...arguments);

    this.checkTheme();

    // Mixpanel initialisation
    this.analytics.initialise();

    this.router.on('routeDidChange', () => {
      // when some link is clicked in SS we want to always close SS
      PS.Pub('close.smart.search');
    });

    this.loadFeatureFlags();
  }

  loadFeatureFlags() {
    const flagsForApplication = [
      'web_is_comments_feed_enabled',
      'web_are_ai_stickers_enabled',
      'web_is_search_autocomplete_enabled',
    ];

    flagsForApplication.forEach((flag) => {
      getConfigCatFlagPromise(flag).then((value) => {
        verbose('ConfigCat flag ' + flag + ': ' + value);
        let flags = this.account.featureFlags;
        flags.push(EmberObject.create({ name: flag, value }));
        this.account.featureFlags = flags;
      });
    });
  }

  checkTheme() {
    // setting theme based on settings from local storage to make it faster.
    this.settings.promise().then((data) => {
      // don't set theme if there are no settings - that means user is not logged in
      if (Object.keys(data).length === 0) return;

      // if a theme was set even before application route then leave it as is
      // (dark theme could be set on static pages as we have DM design on them)
      if (!hasAnyThemeClass()) {
        setTheme(this.settings.activeTheme);
      }
    });
  }

  beforeModel(transition) {
    if (transition.to.name.match(/^join-page-front/)) {
      let params = this.paramsFor('join-page-front');

      return Session.isAuthenticated().then(({ isAuthenticated }) => {
        if (isAuthenticated) {
          this.replaceWith(window.location.pathname.replace('p-front', 'p'));
        } else {
          if (params && params.page_public_id) {
            this.router.transitionTo('join-page', params.page_public_id);
          }
        }
      });
    }
  }

  activate() {
    const { body } = getOwner(this).lookup('service:-document');

    body.classList.add(...browserClasses());
  }

  @action
  error(error) {
    if (error && error.status === 401) {
      this.get('session').invalidate();
    }

    //Global 404 handler, this will catch 404 if not handle 404 somewhere else
    if (error && error.status === 404) {
      location.href = '/404';
    }

    return true;
  }
}
