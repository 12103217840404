import ApiUtil from './api';

/**
 * import SmartSearchApi from 'mewe/api/smart-search-api';
 *
 * uses API v3
 *
 */
class Api extends ApiUtil {
  /**
   * Type of records that can be returned:
   * - post
   * - group
   * - user
   * - chatMessage
   * - chatThread
   * - comment
   */
  doSearch(url, params) {
    params.nm = 1;
    return this.get(
      url,
      {
        data: params,
      },
      'apiRoot3'
    );
  }

  /**
   * Params:
   *
   * - query
   * - page
   * - pageSize
   * - groupLimit
   * - highlightStart
   * - hightlightEnd
   */
  top(params) {
    params.withPages = true;
    return this.doSearch('/desktop/search', params); // <-- /api/v3/desktop/search
  }
  groups(params) {
    return this.doSearch('/desktop/search/groups', params);
  }
  pages(params) {
    return this.doSearch('/desktop/search/pages', params);
  }
  publicOpenGroups(params) {
    return this.doSearch('/desktop/search/groups/public', params);
  }
  members(params) {
    return this.doSearch('/desktop/search/users', params);
  }

  /**
   * ATTENTION :)
   *
   * The following ones instead of: page & pageSize use:
   * - offset
   * - limit
   */
  chats(params) {
    return this.doSearch('/desktop/search/chats', params);
  }
  posts(params) {
    params.withPages = true;
    return this.doSearch('/desktop/search/posts', params);
  }
  search(params) {
    return this.get('/search/autocomplete', { data: params });
  }
}

export default new Api();
