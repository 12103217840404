import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import AccountApi from 'mewe/api/account-api';
import ContactsApi from 'mewe/api/contacts-api';
import ChatApi from 'mewe/api/chat-api';
import CurrentUserStore from 'mewe/stores/current-user-store';
import dispatcher from 'mewe/dispatcher';

export default class AppSettings extends Component {
  @tracked loaded;

  constructor() {
    super(...arguments);

    CurrentUserStore.getState().deferred.promise.then(() => {
      this.loaded = true;
    });

    Promise.all([
      AccountApi.getData(),
      AccountApi.getSpaceUsedStatus(),
      ContactsApi.getMyContactsSettings(),
      ChatApi.isChatRequestsAllowed(),
    ]).then((data) => {
      this.storeSettings(data);
    });
  }

  storeSettings(data) {
    let accountSettings = data[0];
    let cloudStorage = data[1];
    let contactsSettings = data[2];
    let chatRequests = data[3];

    let settings = {
      timezones: accountSettings.timezones,
      cloudStorage: cloudStorage,
    };

    settings.account = accountSettings;

    settings.privacy = {
      public: accountSettings.public,
      allowSearchDirectory: accountSettings.active,
      hideFollowers: accountSettings.hideFollowers,
      allowChatRequests: chatRequests ? chatRequests.allowRequests : false,
    };

    settings.notifs = {
      emailNotifications: accountSettings.emailNotifications,
      allowChatMsgSound: contactsSettings.allowChatMsgSound,
      allowNotifSound: contactsSettings.allowNotifSound,
    };

    dispatcher.dispatch('client-data', 'saveClientPreference', 'settings', settings);
  }
}
