import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

import PS from 'mewe/utils/pubsub';
import { WrapperScope } from 'mewe/constants';
import { ds } from 'mewe/stores/ds';
import { toWrapper } from 'mewe/stores/models/wrapper-model';
import { isSimpleClick } from '@ember/-internals/views';

export default class MwGroupMenuItem extends Component {
  @service router;
  @service dynamicDialogs;

  feedRouteName = 'app.group.index.feed';

  refreshGroupFeed() {
    //only refresh when on feed page
    if (this.router.currentRouteName !== this.feedRouteName) {
      return;
    }

    later(
      this,
      function () {
        if (this.isDestroying || this.isDestroyed) return;

        const route = getOwner(this).lookup(`route:${this.feedRouteName}`);
        if (route) route.send('refreshFeed');
      },
      80
    );
  }

  @computed('args.isInTopMenu', 'args.group.newPosts')
  get showPostsIcon() {
    return this.args.isInTopMenu && this.args.group.newPosts > 0;
  }

  @action
  openLink(e) {
    if (!isSimpleClick(e)) return;

    e.preventDefault();

    PS.Pub('click.sidebar.search.result');
    window.scrollTo(0, 0);

    if (this.args.group.newPosts > 0) {
      this.refreshGroupFeed();
    }

    this.router.transitionTo('app.group', this.args.group.id, {
      queryParams: {
        previewContext: 'group_invitations_section',
      },
    });
  }

  @action
  openWrapper(group) {
    const isGroupInStore = ds.wrappers.items.some((i) => i.id === group.id);

    if (!isGroupInStore) {
      ds.wrappers.items.pushObject(
        toWrapper({
          postsCount: group.newPosts,
          id: group.id,
          type: 'group',
          group: group,
        })
      );
    }

    this.dynamicDialogs.openDialog('wrappers-dialog', {
      wrapperId: group.id,
      wrappersScope: WrapperScope.GROUP,
    });
  }
}
