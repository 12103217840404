import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { escape, unescape } from 'lodash';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';

import { ds } from 'mewe/stores/ds';
import { fetchCategoriesAll } from 'mewe/fetchers/fetch-pages';
import toServer from 'mewe/stores/text-parsers/to-server';
import EmojisParser from 'mewe/stores/text-parsers/emojis-parser';
import dispatcher from 'mewe/dispatcher';
import { getEmojisPromise } from 'mewe/utils/emoji-utils';
import { pageHandleRegex } from 'mewe/shared/utils';
import config from 'mewe/config';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { next } from '@ember/runloop';

class Errors {
  @tracked publicLinkIdError = false;
}

export default class AppPageSettingsGeneral extends Component {
  photoDialogCoverTitles = {
    choosePhoto: __('Choose Page Background Photo'),
    cropPhoto: __('Edit Page Background Photo'),
  };
  profilePhotoDialogTitles = {
    choosePhoto: __('Choose Page Profile Photo'),
    cropPhoto: __('Edit Page Profile Photo'),
  };

  maxDescriptionLength = 3000;

  @service tasks;
  @service router;
  @service dynamicDialogs;

  @alias('args.model.appPage.page') page;

  @tracked searchedItems;
  @tracked searchValue;
  @tracked selectedCategory;
  @tracked descriptionReady;
  @tracked description;
  @tracked categories;
  @tracked taskCover;
  @tracked taskAvatar;
  @tracked loaded;
  @tracked name;
  @tracked publicLinkId;
  @tracked dropdownAvatarMenuOpened;
  @tracked dropdownCoverMenuOpened;
  @tracked errors;
  @tracked publicLinkIdHint;

  constructor() {
    super(...arguments);

    this.errors = new Errors();

    this.urlStaticPart = `${config.apiHost}/`;

    this.selectPageCoverBind = this.selectPageCover.bind(this);
    this.selectPageProfileBind = this.selectPageProfile.bind(this);

    this.categories = ds.categoriesAll;
    this.taskCover = this.tasks.getTask(`photo.cover.page.${this.args.model.appPage.pageId}`);
    this.taskAvatar = this.tasks.getTask(`photo.avatar.page.${this.args.model.appPage.pageId}`);

    if (!this.categories.items.length && !this.categories.isFetching) {
      next(() => {
        fetchCategoriesAll();
      });
    }

    addObserver(this, 'page.name', this.pageLoaded);
  }

  @action
  onInsert() {
    this.pageLoaded();
  }

  willDestroy() {
    this.loaded = false;
    removeObserver(this, 'page.name', this.pageLoaded);
  }

  pageLoaded() {
    if (this.page.name && !this.loaded) {
      this.loaded = true;
      this.selectedCategory = this.page.category;
      this.publicLinkId = this.page.publicLinkId;
      this.name = this.page.name || '';

      getEmojisPromise().promise.then(() => {
        this.setPageDescription();
      });
    }
  }

  @action
  updateDescription(description) {
    next(() => {
      this.description = description;
    });
  }

  setPageDescription() {
    if (this.page.description) {
      this.description = EmojisParser.toEdit(escape(this.page.description));
    } else {
      this.description = '';
    }

    this.descriptionReady = true;
  }

  @computed('name.length', 'loaded')
  get isNameMissing() {
    return this.loaded && (!this.name || this.name.trim().length === 0);
  }

  @computed('publicLinkId.length', 'loaded')
  get isPublicLinkIdMissing() {
    return this.loaded && !this.publicLinkId?.length;
  }

  @computed('isSaving', 'loaded')
  get isPending() {
    return this.isSaving || !this.loaded;
  }

  @computed(
    'page.{name,category.id,description,publicLinkId}',
    'name',
    'publicLinkId',
    'description',
    'selectedCategory.id',
    'isNameMissing',
    'isPublicLinkIdMissing',
    'isSaving'
  )
  get isSaveVisible() {
    const nameChanged = this.page.name !== this.name;
    const publicLinkIdChanged = this.page.publicLinkId !== this.publicLinkId;
    const categoryChanged = this.page.category?.id !== this.selectedCategory?.id;

    const oldText = toServer(unescape(this.page.description), { parseNativeMarkdown: true });
    const newText = toServer(unescape(this.description), { parseNativeMarkdown: true });
    const descriptionChanged = oldText !== newText;

    return (
      (nameChanged || categoryChanged || descriptionChanged || publicLinkIdChanged) &&
      !this.isSaving &&
      !this.isNameMissing &&
      !this.isPublicLinkIdMissing &&
      this.selectedCategory?.id
    );
  }

  selectPageCover(blob, params) {
    dispatcher.dispatch('pages', 'sendPageImage', this.page.id, blob, params, this.taskCover, 'cover');
  }

  selectPageProfile(blob, params) {
    dispatcher.dispatch('pages', 'sendPageImage', this.page.id, blob, params, this.taskAvatar, 'avatar');
  }

  @action
  changePageCategory(category) {
    this.selectedCategory = category;
  }

  @action
  clearErrorLink() {
    this.errors.publicLinkIdError = false;
    this.publicLinkIdHint = null;
  }

  @action
  saveSettings() {
    let isPublicLinkChanged = false;

    this.publicLinkId = this.publicLinkId.toLowerCase();

    if (!pageHandleRegex.test(this.publicLinkId)) {
      this.errors.publicLinkIdError = true;
      this.publicLinkIdHint = __(
        'Page link must be between 4 to 16 char long, no special characters except underscore “_”'
      );
      return;
    }

    if (this.isNameMissing || this.isSaving) return;

    this.isSaving = true;

    let params = {
      name: this.name.trim(),
      categoryId: this.selectedCategory.id,
      description: toServer(unescape(this.description.trim()), {
        parseNativeMarkdown: true,
      }).slice(0, this.maxDescriptionLength),
    };

    //work around to avoid sending publicLinkId on payload in case it has not been changed. This will avoid the BE to throw the reservation timer error
    if (this.page.publicLinkId !== this.publicLinkId) {
      params.publicLinkId = this.publicLinkId;
      isPublicLinkChanged = true;
    }

    const callback = () => {
      this.isSaving = false;

      let publicLinkId = params?.publicLinkId || this.publicLinkId;

      if (this.page.isVerified && isPublicLinkChanged) {
        this.page.isVerified = false;
      }

      this.page.setProperties({
        name: params.name,
        publicLinkId: publicLinkId,
        category: this.selectedCategory,
        description: params.description,
      });

      let followedPage = ds.followed.items.find((i) => i.id === this.page.id);
      followedPage.setProperties({
        name: params.name,
        publicLinkId: publicLinkId,
        description: params.description,
        category: this.selectedCategory,
      });

      this.router.transitionTo('app.publicid.settings.general', publicLinkId);
    };
    const fail = (data) => {
      this.isSaving = false;
      if (data.status === 409) {
        this.errors.publicLinkIdError = true;
        this.publicLinkIdHint = __('The page link is taken or invalid. We suggest you use {proposedHandle}', {
          proposedHandle: '<b class="proposed-handle">' + data.data.available + '</b>',
        });
      } else if (data.status === 400) {
        this.errors.publicLinkIdError = true;
        if (data.data.errorCode === 121) {
          this.publicLinkIdHint = __(
            'You cannot change your Page link because you changed it within the last 30 days. You can change your Page Link in {days} days.',
            {
              days: '<b class="proposed-handle">' + data.data.message + '</b>',
            }
          );
        } else {
          this.publicLinkIdHint = __('The page link is taken or invalid.');
        }
      } else {
        FunctionalUtils.showDefaultErrorMessage();
      }
    };

    if (isPublicLinkChanged && this.page.isVerified) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        okButtonText: __(`Change page name`),
        imagePath: '/assets/icons/badge_verified.png',
        classImg: 'box-shadow-none mt-24 small-size',
        messageCustomClass: 'mt-16 f-14b',
        message: __(`If you change your page link, your page will no longer be verified.`),
        noEscaping: true,
        onConfirm: async () => {
          dispatcher.dispatch('pages', 'saveSettings', this.page, params, callback, fail);
        },
        onClose: async () => {
          this.isSaving = false;
        },
      });
    } else {
      dispatcher.dispatch('pages', 'saveSettings', this.page, params, callback, fail);
    }
  }

  @action
  setCategorySearchValue(searchValue) {
    this.searchValue = searchValue;
  }

  @action
  setCategorySearchedItems(searchedItems) {
    this.searchedItems = searchedItems;
  }

  @action
  setDropdownAvatarMenuOpened() {
    this.dropdownAvatarMenuOpened = !this.dropdownAvatarMenuOpened;
  }

  @action
  setDropdownCoverMenuOpened() {
    this.dropdownCoverMenuOpened = !this.dropdownCoverMenuOpened;
  }
}
