/* eslint-disable no-restricted-syntax */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class DebugDialog extends Component {
  @service account;
  @service dynamicDialogs;
  @service router;

  @tracked imageUrl;
  @tracked debugText;
  @tracked enableAuthPass = localStorage.getItem('authPass') === 'true';
  @tracked authMode = localStorage.getItem('authMode') || 'mewe';
  @tracked selectedTab = 'general';
  @tracked verboses;

  constructor() {
    super(...arguments);
    document.addEventListener('keydown', this.onDocumentKeydown);

    const storedConfig = localStorage.getItem('verboseConfig');
    const parsedConfig = storedConfig ? JSON.parse(storedConfig) : null;

    const defaultVerboses = [
      { prefix: '[Config]', color: '#000000' },
      { prefix: '[Feed Action Controller]', color: '#0000FF' },
      { prefix: '[Stories]', color: '#008080' },
      { prefix: '[Fetch Collection]', color: '#0000FF' },
      { prefix: '[Impression]', color: '#FFA500' },
      { prefix: '[text-editor-autocomplete]', color: '#FF0000' },
      { prefix: '[Signup/config]', color: '#FFA500' },
      { prefix: '[Postbox]', color: '#FF4500' },
      { prefix: '[Postbox/photo]', color: '#0000FF' },
      { prefix: '[Emoji Picker]', color: '#FF8C00' },
      { prefix: '[Search]', color: '#008080' },
      { prefix: '[App Route]', color: '#008000' },
      { prefix: '[Application Route]', color: '#008000' },
      { prefix: '[Analytics service]', color: '#FF0000' },
      { prefix: '[Session Timer]', color: '#FF8C00' },
      { prefix: '[Journals Service]', color: '#000080' },
      { prefix: '[Purchase Service]', color: '#FF8C00' },
      { prefix: '[Settings Service]', color: '#ADD8E6' },
      { prefix: '[WS]', color: '#000080' },
      { prefix: '[ConfigCat]', color: '#FF0000' },
      { prefix: '[Sentry]', color: '#FF0000' },
      { prefix: '[markdown-parser]', color: '#000080' },
      { prefix: '{Text Parsers - toServer}', color: '#FF0000' },
      { prefix: '[Fileupload Utils]', color: '#000080' },
      { prefix: '[Comment-WS]', color: '#000080' }
    ];

    this.verboses = defaultVerboses.map(defaultItem => {
      const storedItem = parsedConfig?.find(item => item.prefix === defaultItem.prefix);
      return {
        prefix: defaultItem.prefix,
        color: storedItem?.color || defaultItem.color,
        enabled: storedItem?.enabled ?? false
      };
    });

    // Save initial configuration if none exists
    if (!parsedConfig) {
      localStorage.setItem('verboseConfig', JSON.stringify(this.verboses));
    }

    this.debugText = this.getDebugText();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    document.removeEventListener('keydown', this.onDocumentKeydown);
  }

  @action
  authModeChange(mode) {
    this.authMode = mode;
    localStorage.setItem('authMode', mode);
    window.location.reload();
  }

  @action
  handleColorChange(item, event) {
    const newColor = event.target.value;
    const index = this.verboses.indexOf(item);
    if (index !== -1) {
      this.verboses = [
        ...this.verboses.slice(0, index),
        { ...item, color: newColor },
        ...this.verboses.slice(index + 1)
      ];
    }
    localStorage.setItem('verboseConfig', JSON.stringify(this.verboses));
  }

  @action
  handleEnabledChange(item) {
    const index = this.verboses.indexOf(item);
    if (index !== -1) {
      this.verboses = [
        ...this.verboses.slice(0, index),
        { ...item, enabled: !item.enabled },
        ...this.verboses.slice(index + 1)
      ];
      localStorage.setItem('verboseConfig', JSON.stringify(this.verboses));
    }
  }

  @action
  changeEnableAuthPass() {
    this.enableAuthPass = !this.enableAuthPass;
    localStorage.setItem('authPass', this.enableAuthPass);
    window.location.reload();
  }

  getDebugText() {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';

    if (userAgent.includes('Firefox/')) {
      const version = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || '';
      browserName = `Firefox ${version}`;
    } else if (userAgent.includes('Chrome/')) {
      const version = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || '';
      browserName = `Chrome ${version}`;
    } else if (userAgent.includes('Safari/') && !userAgent.includes('Chrome')) {
      const version = userAgent.match(/Version\/([\d.]+)/)?.[1] || '';
      browserName = `Safari ${version}`;
    } else if (userAgent.includes('Edg/')) {
      const version = userAgent.match(/Edg\/([\d.]+)/)?.[1] || '';
      browserName = `Edge ${version}`;
    }

    return `
Environment:
    - *Current URL*: ${window.location.href}
    - *Current datetime*: ${new Date().toISOString()}
    - *Current route*: ${this.router.currentRouteName}
    - *Browser*: ${browserName}

Current user:
    - *ID*: ${this.account.activeUser.id}
    - *Username*: ${this.account.activeUser.name}
    - *Email*: ${this.account.activeUser.primaryEmail}
    - *Phone*: ${this.account.activeUser.primaryPhoneNumber}
    - *Public Link ID*: ${this.account.activeUser.publicLinkId}
    - *Public*: ${this.account.activeUser.public}
`;
  }

  async capture() {
    try {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      const captureStream = await navigator.mediaDevices.getDisplayMedia({
        preferCurrentTab: true,
      });

      video.srcObject = captureStream;
      await new Promise((resolve) => (video.onloadedmetadata = resolve));
      await video.play();

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      captureStream.getTracks().forEach((track) => track.stop());

      this.imageUrl = canvas.toDataURL('image/png');
      this.canvas = canvas;
    } catch (err) {
      FunctionalUtils.error('Failed to capture screen');
      this.imageUrl = null;
    }
  }

  @action
  async copyText() {
    try {
      const text = new Blob([this.debugText], { type: 'text/plain' });
      await navigator.clipboard.write([new ClipboardItem({ 'text/plain': text })]);
      FunctionalUtils.info('Text copied to clipboard');
    } catch (err) {
      FunctionalUtils.error('Failed to copy text to clipboard');
    }
  }

  @action
  async copyImage() {
    try {
      const imageBlob = await getCanvasBlob(this.canvas);
      const clipboardItem = new ClipboardItem({ 'image/png': imageBlob });
      await navigator.clipboard.write([clipboardItem]);
      FunctionalUtils.info('Image copied to clipboard');
    } catch (err) {
      FunctionalUtils.error('Failed to copy image to clipboard');
    }
  }

  @action
  close() {
    this.dynamicDialogs.close(this.args.compArgs.dialogId);
  }

}

function getCanvasBlob(canvas) {
  return new Promise(function (resolve /*, reject*/) {
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
}
