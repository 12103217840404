import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MwCloudStorageUsage extends Component {
  @service dynamicDialogs;
  @service purchase;

  get canGetMoreSpace() {
    // don't show until we have the data
    if (!this.args.cloudStorageInfo) return false;

    // max storage: 8GB free, 100GB from premium, 500GB from storage subscription.
    // hardcoded number because we don't have this info from the server without
    // request for checking storage subscription tier, and this seems reliable enough
    return this.args.cloudStorageInfo.totalGB < 600;
  }

  @action
  getMoreSpace() {
    // suggest Premium purchase to get more space if not already purchased
    // otherwise suggest to buy more space
    if (this.purchase.get('premium.purchased')) {
      this.dynamicDialogs.openDialog('store/store-item-premium-dialog');
    } else {
      this.dynamicDialogs.openDialog('store/store-item-storage-dialog');
    }
  }

  @action
  openDownloadContentDialog() {
    this.dynamicDialogs.openDialog('download-content-dialog');
  }
}
