export const sortMediaOptions = (params = {}) => {
  const { bySize, byName, byType } = params;
  const options = [];

  options.push({
    orderBy: 'date',
    orderAsc: false,
    label: __('Newest'),
    icon: 'icn_newest',
    action: 'orderMedia',
    selected: true,
    default: true,
    order: 0, // used for sorting photos/albums/tags
  });

  options.push({
    orderBy: 'date',
    orderAsc: true,
    label: __('Oldest'),
    icon: 'icn_oldest',
    action: 'orderMedia',
      selected: false,
      order: 1, // used for sorting photos/albums/tags
    },
  );

  if (bySize) {
    options.push({
      orderBy: 'size',
      orderAsc: false,
      label: __('Largest size'),
      icon: 'icn_file_large',
      action: 'orderMedia',
      selected: false,
    });
    options.push({
      orderBy: 'size',
      orderAsc: true,
      label: __('Smallest size'),
      icon: 'icn_file_small',
      action: 'orderMedia',
      selected: false,
    });
  }

  if (byName) {
    options.push({
      orderBy: 'name',
      orderAsc: true,
      label: __('Alphabetical ascending'),
      icon: 'icn_alphabetical_ascending',
      action: 'orderMedia',
      selected: false,
    });
    options.push({
      orderBy: 'name',
      orderAsc: false,
      label: __('Alphabetical descending'),
      icon: 'icn_alphabetical_descending',
      action: 'orderMedia',
      selected: false,
    });
  }

  if (byType) {
    options.push({
      orderBy: 'type',
      orderAsc: true,
      label: __('Kind'),
      icon: 'icn_category',
      action: 'orderMedia',
      selected: false,
    });
  }

  return {
    subOptions: options,
  };
};

export const sortMembersOptions = () => {
  return {
    subOptions: [
      {
        label: __('Recently Added'),
        action: 'sortMembers',
        selected: true,
        default: true,
        order: 'recency',
      },
      {
        label: __('First Name'),
        action: 'sortMembers',
        selected: false,
        order: 'firstname',
      },
      {
        label: __('Last Name'),
        action: 'sortMembers',
        selected: false,
        order: 'lastname',
      },
    ],
  }
};
