import config from 'mewe/config';

const getVerboseConfig = () => {
  try {
    const storedConfig = localStorage.getItem('verboseConfig');
    return storedConfig ? JSON.parse(storedConfig) : null;
  } catch (e) {
    return null;
  }
};

export default ({ prefix = '[verbose]', color = 'blue', enabled = false, style }) => {
  const _getVerboseColor = () => {
    const verboseConfig = getVerboseConfig();
    const configuredColor = verboseConfig?.find(item => item.prefix === prefix)?.color;
    return `color: ${configuredColor || color}; ${style || ''}`;
  };

  const _isLogEnabled = () => {
    const verboseConfig = getVerboseConfig();
    const isPrefixEnabled = verboseConfig?.find(item => item.prefix === prefix)?.enabled ?? false;
    return (config.environment !== 'prod' && enabled) || window.meweVerbose || isPrefixEnabled;
  };

  return {
    isEnabled: _isLogEnabled,

    log: (...args) => {
      if (!_isLogEnabled()) return;

      const verboseColor = _getVerboseColor();
      args.forEach((text) => {
        if (text !== null && (typeof text === 'object' || Array.isArray(text))) {
          console.log(`%c${prefix}`, verboseColor, text);
        } else {
          console.log(`%c${prefix} ${text}`, verboseColor);
        }
      });
    },
  };
};
