import Quill from 'quill/core';
import { remove } from 'lodash';
import EmojiUtils from 'mewe/utils/emoji-utils';
import ScrollingUtils from 'mewe/utils/scrolling-utils';
import {
  getQuillBoundsPosition,
  getDelta,
  insertEmoji,
  insertHashTag,
  insertMention,
  checkAutocompleteTopPlacement,
} from './text-editor-quill-utils';
import Verbose from 'mewe/utils/verbose';
import dispatcher from 'mewe/dispatcher';

/**
 * AutocompleteProxy
 *
 * This class serves as a communication layer between the text editor autocomplete
 * and the autocomplete dropdown component. It provides methods to interact with
 * the popup component when direct access is no longer available.
 */
class AutocompleteProxy {
  setPopup(popup) {
    this.popup = popup;
  }
}

const verbose = Verbose({ prefix: '[text-editor-autocomplete]' });

const Module = Quill.import('core/module');

const Keys = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  SPACE: 32,
};

class Autocomplete extends Module {
  constructor(quill, options) {
    super(quill, options);

    const hashtag = {
      strategy: options.hashtagsStrategy,
      trigger: /(?:^|\s)#/,
      sign: '#',
    };

    const mention = {
      strategy: options.mentionsStrategy,
      trigger: /(?:^|\s)@/,
      sign: '@',
    };

    const emoji = {
      strategy: EmojiUtils.textCompleteStrategy(),
      trigger: /(^|\s)\:/,
      sign: ':',
    };

    this.transforms = { emoji };

    if (options.mentionsStrategy) this.transforms['mention'] = mention;
    if (options.hashtagsStrategy) this.transforms['hashtag'] = hashtag;

    this.emberContext = options.emberContext;

    if (options.groupName) this.groupName = options.groupName;

    this.registerTypeListener();
  }

  registerTypeListener() {
    this.quill.keyboard.addBinding(
      {
        key: Keys.UP,
        collapsed: true,
      },
      this.forwardKeyboardUp.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        key: Keys.DOWN,
        collapsed: true,
      },
      this.forwardKeyboardDown.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        id: 'text-autocomplete',
        key: Keys.ENTER,
        collapsed: true,
      },
      this.forwardKeyboardEnter.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        id: 'text-autocomplete',
        key: Keys.TAB,
        collapsed: true,
      },
      this.forwardKeyboardEnter.bind(this)
    );

    this.quill.keyboard.addBinding(
      {
        key: Keys.ESCAPE,
        collapsed: true,
      },
      this.deactivateHelper.bind(this)
    );

    this.quill.on(Quill.events.TEXT_CHANGE, this.onChange.bind(this));
  }

  getMentionCharIndex(text, triggers) {
    return triggers.reduce(
      (prev, trigger) => {
        const index = text?.lastIndexOf(trigger);

        if (index > prev.index) {
          return {
            trigger,
            index,
          };
        }
        return {
          trigger: prev.trigger,
          index: prev.index,
        };
      },
      { trigger: null, index: -1 }
    );
  }

  onChange(delta, oldDelta, source) {
    const ops = delta.ops;
    const noWhitespaceRegexForEmoji = /\S+:\S+/;

    if (source !== 'user' || !ops || ops.length < 1) {
      return;
    }

    if (this.active) {
      let sel = this.quill.getSelection();

      let [leaf] = this.quill.getLeaf(sel.index);
      let text = leaf.text || '';
      let textFromSign = text.substr(text.lastIndexOf(this.transform.sign));

      // SG-30284 - Make sure helper is deactivated after no emoji, hashtag or mention match
      const shouldDeactivateHelper =
        !text || text === ' ' || text.indexOf(this.transform.sign) === -1 || textFromSign === this.transform.sign + ' ';

      if (shouldDeactivateHelper) {
        this.deactivateHelper();
      } else {
        let pos = getQuillBoundsPosition(this.quill, sel.index);

        this.proxy.popup.position = pos;
        this.proxy.popup.term = textFromSign;

        this.employMatchChecker(textFromSign);
      }
    } else {
      let range = this.quill.getSelection();

      const textBeforeCursor = this.quill.getText(0, range?.index);

      const textBeforeCursorSplit = textBeforeCursor.split(/\s/);

      const wordBeforeCursor = textBeforeCursorSplit[textBeforeCursorSplit.length - 1];

      const signs = Object.keys(this.transforms).map((el) => this.transforms[el].sign);

      // SG-30921 - Prevent activating emoji helper when no whitespace present in the text
      const whitespacePresentInTheText = !noWhitespaceRegexForEmoji.test(wordBeforeCursor);

      if (signs.indexOf(wordBeforeCursor) === -1 && whitespacePresentInTheText) {
        const { trigger, index } = this.getMentionCharIndex(wordBeforeCursor, signs);

        if (index > -1) {
          let transform = Object.keys(this.transforms).filter((el) => this.transforms[el].sign === trigger);

          this.activateHelper(
            this.transforms[transform],
            range.index,
            wordBeforeCursor.length,
            wordBeforeCursor.replaceAll('@', '')
          );
        }
      }
    }

    let lastOpIndex = ops.length - 1;
    let lastOp = ops[lastOpIndex];

    while (!lastOp.insert && lastOpIndex > 0) {
      lastOpIndex--;
      lastOp = ops[lastOpIndex];
    }

    if (!lastOp.insert || typeof lastOp.insert !== 'string') {
      return;
    } else if (lastOp.insert === '\n' && this.active) {
      this.deactivateHelper();
    }

    let sel = this.quill.getSelection();
    if (!sel) {
      return;
    }

    let checkIndex = sel.index;
    let [leaf] = this.quill.getLeaf(checkIndex);

    if (!leaf || !leaf.text) {
      return;
    }

    for (const name in this.transforms) {
      const transform = this.transforms[name];

      if (lastOp.insert.match(transform.trigger || /./)) {
        if (lastOp.insert.match(transform.trigger)) {
          if (!this.active) this.activateHelper(transform, checkIndex);
          continue;
        }
      }
    }
  }

  employMatchChecker(userInput) {
    if (this.proxy.popup.strategy.id === 'emoji') this.isEmojiMatching(userInput);
    if (this.proxy.popup.strategy.id === 'mention') this.isMentionMatching(userInput);
  }

  isEmojiMatching(userInput) {
    const emojiMatchFound = this.proxy.popup.keyboardListItems?.find((item) => {
      return item === userInput || item.includes(userInput);
    });

    this.adjustPopupToMatchingResults(emojiMatchFound);
  }

  isMentionMatching(userInput) {
    const mentionMatchFound = this.proxy.popup.keyboardListItems?.find((item) => {
      const userInputAsMention = userInput.replaceAll(' ', '').toLowerCase();
      const availableMention = '@' + item.user.name.replaceAll(' ', '').toLowerCase();
      return availableMention === userInputAsMention || availableMention.includes(userInputAsMention);
    });
    this.adjustPopupToMatchingResults(mentionMatchFound);
  }

  adjustPopupToMatchingResults(matchFound) {
    if (!matchFound) {
      this.proxy.popup.dropdownOpened = false;
    } else {
      this.proxy.popup.dropdownOpened = true;
    }
  }

  forwardKeyboard(range, context) {
    if (this.active) {
      const target = !!this.proxy.popup && this.proxy.popup?.element.querySelector('.dropdown-menu-wrapper');

      if (target) {
        // SG-30918 - Prevent scrolling of the whole window while browsing autocomplete popup
        ScrollingUtils().disableWindowScroll();

        this.proxy.popup.keyDown(context.event);

        //SG-30918 - Scrolling needs to be brought back after keyboard event is done
        setTimeout(() => {
          ScrollingUtils().enableWindowScroll();
        }, 1000);
      }
    }
  }

  forwardKeyboardUp(range, context) {
    if (this.opened) {
      var e = new KeyboardEvent('keydown', {
        key: 'ArrowUp',
        keyCode: 38,
        which: 38,
        bubbles: true,
        cancelable: true,
      });
      context.event = e;
      this.forwardKeyboard(range, context);
    } else {
      return true;
    }
  }

  forwardKeyboardDown(range, context) {
    if (this.opened) {
      var e = new KeyboardEvent('keydown', {
        key: 'ArrowDown',
        keyCode: 40,
        which: 40,
        bubbles: true,
        cancelable: true,
      });
      context.event = e;
      this.forwardKeyboard(range, context);
    } else {
      return true;
    }
  }

  forwardKeyboardEnter(range, context) {
    if (this.emberContext.autocompleteVisible) {
      var e = new KeyboardEvent('keydown', {
        key: 'Enter',
        keyCode: 13,
        which: 13,
        bubbles: true,
        cancelable: true,
      });
      context.event = e;
      this.forwardKeyboard(range, context);
    } else {
      return true;
    }
  }

  onHelperVisibilityChange(isVisible) {
    if (this.emberContext.isDestroying || this.emberContext.isDestroyed) return;

    const bind = remove(this.quill.keyboard.bindings[Keys.ENTER], (el) => el.id == 'text-autocomplete');

    if (isVisible) {
      // reorder enter binding so functions from registerTypeListener are on top
      this.quill.keyboard.bindings[Keys.ENTER].unshift(bind[0]);
    } else {
      // reorder enter binding so one from text-editor-quill is on top
      this.quill.keyboard.bindings[Keys.ENTER].push(bind[0]);
    }

    this.emberContext.autocompleteVisible = isVisible;

    this.opened = isVisible;
  }

  activateHelper(transform, index, alreadyTyped, wordBeforeCursor) {
    if (transform) {
      this.onHelperVisibilityChange(true);

      let pos = getQuillBoundsPosition(this.quill, index);
      let forceTopPlacement = checkAutocompleteTopPlacement(this.quill.container);

      this.active = true;
      this.transform = transform;
      this.proxy = new AutocompleteProxy();
      dispatcher.dispatch('app', 'addPopups', 'autocomplete-dropdown', {
        position: pos,
        strategy: transform.strategy,
        term: wordBeforeCursor || '',
        autocompletePlacement: forceTopPlacement ? 'top' : this.emberContext.args.autocompletePlacement,
        autocompleteFixedPosition: this.emberContext.args.autocompleteFixedPosition,
        closeOnScroll: this.emberContext.args.closeOnScroll,
        groupName: this.groupName,
        proxy: this.proxy,
        onSelect: (value, strategy, term) => {
          this.onSelect(value, strategy, term, transform, alreadyTyped || 0);
        },
        visibilityChange: (isVisible) => {
          this.onHelperVisibilityChange(isVisible);
        },
        onClose: () => {
          this.deactivateHelper();
        },
      }, this.emberContext);
    }
  }

  deactivateHelper() {
    if (this.emberContext.isDestroying || this.emberContext.isDestroyed) {
      dispatcher.dispatch('app', 'removePopups');
      return;
    }

    this.onHelperVisibilityChange(false);

    this.active = false;
    this.transform = null;
    this.proxy?.popup?.close?.();
    this.proxy = null;
    dispatcher.dispatch('app', 'removePopups');
  }

  onSelect(value, strategy, term, transform, alreadyTyped) {
    this.deactivateHelper();

    this.quill.focus();

    const atIndex = this.quill.getSelection().index;

    switch (transform.strategy.id) {
      case 'emoji':
        insertEmoji(getDelta({ value, term, quill: this.quill, atIndex, alreadyTyped: alreadyTyped }));
        break;

      case 'mention':
        if (value.user && value.user.id && value.user.name) {
          const userName = value.user.id === 'everyone' ? 'everyone' : value.user.name;
          this.emberContext.args.mentionsStrategy.mentions.push({ id: value.user.id, name: userName });
        }

        insertMention(getDelta({ value, term, quill: this.quill, atIndex, alreadyTyped: alreadyTyped }));
        break;

      case 'hashtag':
        insertHashTag(getDelta({ value, term, quill: this.quill, atIndex, alreadyTyped: alreadyTyped }));
        break;
    }
  }
}

export { Autocomplete as default };
