import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import AccountApi from 'mewe/api/account-api';
import { getQueryStringParams, addSignupUrlParams } from 'mewe/shared/utils';
import { isDsnpUserWithoutName } from 'mewe/utils/jail-utils';
import { convertHandleToObject } from 'mewe/helpers/handle-html';
import jstz from 'jstimezonedetect';
import CurrentUserStore from 'mewe/stores/current-user-store';
import Storage from 'mewe/shared/storage';

export default class MwSiwaSignin extends Component {
  @service authentication;
  @service router;

  @tracked error;
  @tracked userObject;
  @tracked chosenHandle;
  @tracked firstName;
  @tracked lastName;
  @tracked isSavingNameInProgress;
  @tracked promisesToWaitFor;

  constructor() {
    super(...arguments);

    this.urlParams = getQueryStringParams();

    // checking for `next` param which could be passed to SIWA process in `/auth/dsnp/siwa/request`
    if (this.urlParams.next) {
      // first decode `next` URL because it was encoded on SIWA side
      // then replace all `+` characters with `&` (revert what we did before passing next param to SIWA)
      // then replace first `&` with `?` to have proper format of url params (next=/someUrl?foo=1&bar=2)
      this.urlParams.next = decodeURIComponent(this.urlParams.next)
        .replace(/\+/g, '&')
        .replace('&', '?');
    }

    // authorizationCode is passed when user is redirected from SIWA process.
    // User can also be redirected here without code when he hasn't set his name yet
    // but already logged in and tried opening application.
    if (this.urlParams.authorizationCode) {
      let params = {
        authorizationCode: this.urlParams.authorizationCode,
        timeZone: jstz.determine().name(),
      };

      params = addSignupUrlParams(params, this.urlParams);

      const promise = PublicPagesApi.postSiwa(params, this.urlParams.trc);
      this.promisesToWaitFor = [promise];

      promise.then((res) => {
        if (res.passwordRequired) {
          // redirect to login with password option available
          return window.location.href = '/login#authPass=true';
        }

        // user had multiple identifiers and migrated to SIWA, all other identifiers
        // were removed and we need to show popup about that after redirection to app
        if (res.removedCredentials) {
          this.urlParams.removedCredentials = true;
        }

        if (isDsnpUserWithoutName(res.user)) {
          this.userObject = res.user;
          this.chosenHandle = res.chosenHandle;
        }

        this.authentication.loginCallback(params, res, this.urlParams, false, true);
      })
      .catch((err) => {
        if (err.status === 401 && err.data?.errorCode === 666) {
          this.error = 'banned';
        } else {
          this.error = 'expired';
        }
      });

    } else {

      // request CurrentUser - if user has name already set then redirect to app;
      // if user doesn't have name then he's in right place do set it;
      // if request fails then user is not logged in and redirect to login page
      const promise = AccountApi.getCurrentUser();
      this.promisesToWaitFor = [promise];

      promise.then(data => {
        CurrentUserStore.send('handle', data, true);

        if (!isDsnpUserWithoutName(data)) {
          this.goToApp();
        } else {
          this.userObject = CurrentUserStore.getState();
        }
      })
      .catch((err) => {
        if (err?.status === 401) {
          this.router.transitionTo('login');
        }
      });

    }
  }

  get handleDisplay() {
    // chosenHandle is present when user gets to this page from SIWA process with authorizationCode,
    // but user can also be redirected here without code when he hasn't set his name yet -
    // in such case publicLinkId is the source of the handle
    if (this.chosenHandle) {
      return {
        core: this.chosenHandle,
        suffix: '##',
      };
    } else {
      return convertHandleToObject(this.userObject.publicLinkId);
    }
  }

  get firstNameValue() {
    return (this.firstName || '').trim();
  }

  get lastNameValue() {
    return (this.lastName || '').trim();
  }

  get isSavingNameDisabled() {
    return !this.firstNameValue.length || !this.lastNameValue.length;
  }

  @action
  submitName() {
    this.isSavingNameInProgress = true;

    AccountApi.setData({
      firstName: this.firstNameValue,
      lastName: this.lastNameValue,
    })
      .then(() => {
        this.authentication.redirectAfterRegistration({ siwaMode: true }, {}, this.urlParams);
      })
      .catch((err) => {
        // user has already set name but for some reason user in storage is not up to date,
        // refetch the user and redirect.
        if (err?.data?.errorCode === 114) {
          AccountApi.getCurrentUser().then((data) => {
            CurrentUserStore.send('handle', data, true);
            this.authentication.redirectAfterRegistration({ siwaMode: true }, {}, this.urlParams);
          });
        } else {
          this.error = 'other';
        }
      });
  }

  goToApp() {
    if (this.urlParams.next) {
      window.location.href = this.urlParams.next;
    } else {
      window.location.href = '/';
    }
  }

}