import { scheduleOnce } from '@ember/runloop';

import EnvironmentUtils from 'mewe/utils/environment-utils';
import { isElementInViewport } from 'mewe/utils/miscellaneous-utils-light';

// [?]?# is for android bug that sometimes adds ?#=h200 instead of #h=200
const giphyUrlRegExp = new RegExp(
  '(<a href=")*(http|https)://media[0-9]*.giphy.com/media/(?:v1.[^/]+/)?[0-9a-zA-Z]+/[0-9a-zA-Z_]+.(gif|mp4)(?:[?&][a-zA-Z_]+=[^ #<]+)?([?]?#[wh]=[0-9]+)?',
  'g'
);

const fileSuffixGfycatUrlRegExp =
  '(?:thumbs|giant|fat|zippy).gfycat.com/[0-9a-zA-Z_-]{1,100}.(?:gif|mp4|webp)(?:[?&][a-zA-Z_]{1,100}=[^ #]{1,100})?([?]?#[wh]=[0-9]{1,100}(?:&?[wh]=[0-9]{1,100})?)?';
// gfycat.com/BlaBla, will also match gfycat.com/BlaBla-mobile.mp4 but captures only gfycat.com/BlaBla from it
const nameGfycatUrlRegExp =
  'gfycat.com/[A-Z][0-9a-zA-Z]{1,100}(([?&][a-zA-Z]{1,100}=[^ #-]{1,100})?(#[wh]=[0-9]{1,100}(?:&?[wh]=[0-9]{1,100})?)?)?';

// gfycat.com/blabla, all lowercase so can't be used to display gifs directly, fetched via api
const idGfycatUrlRegExp =
  'gfycat.com/([0-9a-z]{1,100})(([?&][a-zA-Z]{1,100}=[^ #-]{1,100})?(#[wh]=[0-9]{1,100}(?:&?[wh]=[0-9]{1,100})?)?)?';

const gfycatUrlRegExp = new RegExp(
  `(?:<a href=")?(?:http|https):\/\/(?:(?:${nameGfycatUrlRegExp})|(?:${fileSuffixGfycatUrlRegExp}))`,
  'g'
);

export const gifUrlRegExp = new RegExp(`(?:${giphyUrlRegExp.source})|(?:${gfycatUrlRegExp.source})`, 'g');
//export const tenorUrlRegExp = new RegExp('(<a href=")*(http|https):\/\/(thumbs|media)*\.tenor\.com\/images/[0-9a-zA-Z]+/[0-9a-zA-Z]+\.(gif|mp4)(?:[?&][a-zA-Z_]+=[^ #]+)?([?]?#[wh]=[0-9]+)?', 'g');

export function findGifUrl(text) {
  let found = (text || '').match(gifUrlRegExp);
  return found && found[0];
}

export function hasGifUrlOnly(text = '') {
  const gifUrl = findGifUrl(text);
  return gifUrl && gifUrl.length === text.trim().length;
}

export function findPureGfycatId(text) {
  let found = (text || '').match(idGfycatUrlRegExp);
  return found && found.length && found[1];
}

function ifGiphy(doIfGiphy, doElse) {
  return (url) => {
    if (!url) return '';
    if (url.indexOf('giphy.com') !== -1) {
      return doIfGiphy(url);
    } else {
      return doElse(url);
    }
  };
}

function giphyToSmallStill(url) {
  if (!url) return '';
  let slashIndex = url.lastIndexOf('/');
  if (slashIndex !== -1) {
    return url.slice(0, slashIndex + 1) + '100_s.gif';
  }

  return url;
}

const gfycatSuffixRegExp =
  /(?:(?:thumbs|giant|fat|zippy)\.)?gfycat\.com\/([0-9a-zA-Z]+)(?:-[a-zA-Z0-9_\-]*)?(?:.(?:jpg|mp4|webp|gif))?/;
const giphySuffixRegExp = /(?:_s)?.(?:mp4|gif)/;

function replaceUrlSuffix(suffixRegExp, newSuffix) {
  return function (url) {
    if (!url) return '';
    return url.replace(suffixRegExp, newSuffix);
  };
}

export const gfycatToStill = replaceUrlSuffix(gfycatSuffixRegExp, (m, name) => {
  return `thumbs.gfycat.com/${name}-mobile.jpg`;
});
export const gfycatToGif = replaceUrlSuffix(gfycatSuffixRegExp, (m, name) => {
  return `thumbs.gfycat.com/${name}-max-1mb.gif`;
});
export const gfycatToMp4 = replaceUrlSuffix(gfycatSuffixRegExp, (m, name) => {
  return `thumbs.gfycat.com/${name}-mobile.mp4`;
});

const giphyToStill = replaceUrlSuffix(giphySuffixRegExp, '_s.gif');
const giphyToGif = replaceUrlSuffix(giphySuffixRegExp, '.gif');
const giphyToMp4 = replaceUrlSuffix(giphySuffixRegExp, '.mp4');

export const gifToSmallStill = ifGiphy(giphyToSmallStill, gfycatToStill);
export const gifUrlToStill = ifGiphy(giphyToStill, gfycatToStill);
export const gifUrlToMp4 = ifGiphy(giphyToMp4, gfycatToMp4);
export const gifUrlToGif = ifGiphy(giphyToGif, gfycatToGif);
