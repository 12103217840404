import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { Theme } from 'mewe/constants';

export default class AppMediaTab extends Component {
  @service router;
  @service dynamicDialogs;

  @alias('args.model.group') group;
  @alias('args.model.eventState.rootEvent') rootEvent;
  @alias('args.model.page') page;
  @alias('args.model.contact') contact;

  @tracked activeTab = this.args.model.activeTab || 0;
  @tracked albumView = !!this.args.model.albumName;
  @tracked albumName = this.args.model.albumName;

  @computed('args.model.theme', 'args.model.app.theme')
  get theme() {
    return this.args.model.theme || this.args.model.app.theme;
  }

  get isProfile() {
    return this.theme === Theme.PROFILE;
  }

  get containerWidth() {
    return this.isProfile ? '725' : '945';
  }

  get showMediaNav() {
    if (this.isProfile) {
      return this.allowSeeProfileMedia;
    } else {
      return true;
    }
  }

  get allowSeeProfileMedia() {
    return (
      this.contact?.isMe ||
      this.contact?.public ||
      (this.contact?.public === false && this.contact?.following)
    );
  }

  get allowSeeTags() {
    if (this.isProfile) {
      return this.contact?.id && this.contact?.isMe;
    } else {
      return true;
    }
  }

  get showTags() {
    return this.activeTab === 2;
  }

  get showPrivateAccount() {
    return this.isProfile && this.contact?.id && !this.allowSeeProfileMedia;
  }

  get canShowAlbumTab() {
    return this.theme !== Theme.EVENT;
  }

  @computed('isProfile', 'activeTab')
  get showPhotoStream() {
    if (this.isProfile) {
      return this.allowSeeProfileMedia && this.contact?.id && this.activeTab === 0;
    } else {
      return this.activeTab === 0;
    }
  }

  @computed('isProfile', 'activeTab')
  get showAlbums() {
    if (this.isProfile) {
      return this.contact?.id && this.activeTab === 1;
    } else {
      return this.activeTab === 1;
    }
  }

  @computed('theme', 'activeTab')
  get showTags() {
    if (this.isProfile) {
      return this.contact?.id && this.activeTab === 2;
    } else {
      return this.activeTab === 2;
    }
  }

  @action
  switchTab(tabIndex) {
    if (tabIndex === this.activeTab) return false;

    if (this.theme === Theme.PAGE) {
      if (tabIndex === 0) this.router.transitionTo('app.publicid.media', this.page?.publicLinkId);
      else if (tabIndex === 1) this.router.transitionTo('app.publicid.media.albums', this.page?.publicLinkId);
      else if (tabIndex === 2) this.router.transitionTo('app.publicid.media.tags', this.page?.publicLinkId);
    } else {
      this.activeTab = tabIndex;
      this.albumView = false;
    }
  }

  @action
  openAlbumStream(album) {
    if (this.theme === Theme.PAGE) {
      this.router.transitionTo('app.publicid.media.album', this.page?.publicLinkId, album.name);
    } else {
      this.albumName = album.name;
      this.albumView = true;
    }
  }

  @action
  resetTabs() {
    if (this.theme === Theme.PAGE) {
      this.router.transitionTo('app.publicid.media.albums', this.page?.publicLinkId);
    } else {
      this.albumName = '';
      this.albumView = false;
    }
  }
}
