import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import PS from 'mewe/utils/pubsub';
import PhotosApi from 'mewe/api/photos-api';
import PostApi from 'mewe/api/post-api';
import MyCloudApi from 'mewe/api/mycloud-api';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import { Theme } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';

export default class MwRemove extends Component {
  @service dynamicDialogs;

  @computed('args.title')
  get buttonText() {
    return this.args.title || __('Delete');
  }

  @action
  click() {
    this.args.closePostDropdown?.();

    if (this.args.isDocument) {
      if (this.args.post.isFolder) {
        this.deleteFolder();
      } else {
        this.deleteDocument();
      }
    } else if (this.args.isAlbum) {
      this.deleteAlbum();
    } else {
      dispatcher.dispatch('feed', 'deletePost', this.args.post, this.args.onRemovePost);
    }
  }

  deleteFolder() {
    this.dynamicDialogs.openDialog('remove-folder-dialog', {
      folder: this.args.post,
    });
  }

  deleteAlbum() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Confirm Deletion'),
      message: __(
        "Do you really want to delete this album? <br> (Don't worry, none of these photos will be deleted from My Cloud)"
      ),
      noEscaping: true,
      cancelButtonText: __('Cancel'),
      okButtonText: __('Delete'),
      onConfirm: () => {
        const album = this.args.post;

        PhotosApi.deleteAlbum(album.name).then(() => {
          // can be removed only in MC albums list, removing from collection is enough
          this.args.removeFromCollection(album);
          FunctionalUtils.info(__('Album removed'));
        });
      },
    });
  }

  deleteDocument() {
    const post = this.args.post;
    const isDeletingFromMC = post.group.id === Theme.MYCLOUD;
    const callback = () => {
      PS.Pub('documents.reload');
    };

    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Confirm Deletion'),
      message: isDeletingFromMC
        ? __('After you click "Delete", it will be deleted from My Cloud and any post that it is attached to.')
        : __("You're about to delete this document from {groupName}", {
            groupName: post.group.name,
          }),
      cancelButtonText: __('Cancel'),
      okButtonText: __('Delete'),
      onConfirm: () => {
        if (isDeletingFromMC) {
          MyCloudApi.deleteFiles(post.post.id).then(callback);
        } else {
          const scope = post.group.id ? Theme.GROUP : Theme.CONTACTS;
          const scopeId = post.group.id ? post.group.id : Theme.CONTACTS;
          PostApi.deletePost(post.postItemId, scope, scopeId).then(callback);
        }
      },
    });
  }
}
