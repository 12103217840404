import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { ds } from 'mewe/stores/ds';
import dispatcher from 'mewe/dispatcher';

export default class MwEventParticipationSelector extends Component {
  @service router;
  @service dynamicDialogs;
  @service analytics;
  @reads('args.eventData') eventData;
  @tracked isEditingParticipation = false;

  @computed('eventData.isOwner', 'args.{isCurrentUserPostOwner,isPostEventInfo}')
  get isOwner() {
    // situation in post event info rendered via WS
    if (this.args.isPostEventInfo) {
      return this.args.isCurrentUserPostOwner;
    } else {
      return this.eventData.isOwner;
    }
  }

  @computed('eventData.participationType', 'isOwner')
  get participationText() {
    const participationType = this.eventData.participationType;
    let text;

    if (this.eventData.isPastEvent) {
      if (this.isOwner) text = __('You hosted this');
      else if (participationType === 'Attending') text = __('You went to this');
      else text = __('You didn’t go to this');
    } else {
      if (this.isOwner) text = __("You're hosting this event.");
      else if (participationType === 'Interested') text = __('Interested');
      else if (participationType === 'Attending') text = __("You're going to this event.");
      else if (participationType === 'NotAttending') text = __("You're not going to this event.");
    }

    return text;
  }

  @computed('eventData.{eventType,participationType}', 'isEditingParticipation')
  get displayAttendingOptions() {
    const participationType = this.eventData.participationType;

    if (this.eventData.isPastEvent) return false;

    return (
      this.isEditingParticipation ||
      participationType === 'Invited' ||
      participationType === 'Undecided' ||
      (!participationType && (this.eventData.eventType === 'open' || this.eventData.groupId))
    );
  }

  @action
  stopPropagation(e) {
    // in case this component is in other, clickable, component (SG-35072) or link (SG-35088)
    e.stopPropagation();
    e.preventDefault();
  }

  @action
  editParticipation() {
    this.isEditingParticipation = true;
  }

  @action
  setParticipation(type) {
    if (this.args.post?.isPublicContent && this.args.post?.customInteraction) {
      return;
    }

    const scopeId = this.eventData.groupId ?? 'events';

    const callback = () => {
      dispatcher.dispatch('events', 'getCounters', scopeId, () => {
        let counters = ds.eventsCounters[scopeId];
        if (counters?.invitations === 0 && this.args.skin === 'events-list') {
          if (!this.eventData.groupId) {
            this.router.transitionTo('app.events.upcoming');
          } else {
            this.router.transitionTo('app.group.calendar.upcoming');
          }
        }
      });
    };
    dispatcher.dispatch('events', 'setParticipation', this.eventData, type, callback);
    this.isEditingParticipation = false;
  }
}
