import Component from '@glimmer/component';
import { A } from '@ember/array';
import { set } from '@ember/object';
import { each } from 'lodash';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import DocumentsApi from 'mewe/api/documents-api';
import Scrolling from 'mewe/utils/scrolling-utils';

export default class MwFolder extends Component {
  maxResults = 20;

  @action
  setupScroll(element) {
    this.element = element;
    this.scrolling = Scrolling();
    this.scrolling.bindScrollDownElement(element, () => this.loadMore());
  }

  async loadMore() {
    const data = await DocumentsApi.getRootFolderTree({
      foldersOnly: true,
      sortDesc: true,
      offset: this.args.nodes.length,
    }, this.args.nodeContext);

    if (data.list.length >= this.maxResults) {
      this.offset = this.offset + this.maxResults;
      this.scrolling.bindScrollDownElement(this.element, () => this.loadMore());
    } else {
      this.scrolling.unbindScrollDown(this.element);
    }

    this.args.nodes.pushObjects(data.list);
  }

  @action
  destroyScroll() {
    this.scrolling.unbindScrollDown(this.element);
  }

  @task({ drop: true })
  * selectFolder(folder) {
    let options = {
      foldersOnly: true,
      sortDesc: true,
    };

    each(this.args.nodes, (n) => {
      set(n, 'folder', A());
    });

    this.args.setTargetNodeId?.(folder.id);
    this.args.folderSelected();

    const callback = (data) => {
      //remove folder itself, you cannot move folder to itself or to its children (dead loop)
      data.list = data.list.filter((f) => this.args.currentDocumentId !== f.id);
      each(data.list, (el) => (el.folder = A()));

      folder.folder.pushObjects(data.list);
    };

    if (this.args.targetNodeId !== 'root' && !folder.folder.length) {
      if (folder) {
        set(folder, 'selected', true);
      } else {
        folder = {
          id: 'root',
        };
      }

      yield DocumentsApi.getFolderTree(folder.id, options).then((data) => callback(data));
    }
  }
}
