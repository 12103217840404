export default class RequestError extends Error {
  constructor(message, options) {
    super(message, options);

    // Handle case where options or cause might be undefined
    const response = options?.cause?.response;
    const isAborted = options?.cause?.code === 'ERR_CANCELED'
      || options?.cause?.message?.includes('aborted');

    // Set default values for aborted requests
    if (isAborted) {
      this.status = 499; // Client Closed Request
      this.statusText = 'Request Aborted';
      this.data = { message: 'Request was aborted' };
    } else {
      this.data = response?.data;
      this.status = response?.status;
      this.statusText = response?.statusText;
      this.headers = response?.headers;
      this.config = response?.config;
      this.request = response?.request;
    }

    this.name = 'RequestError';
    this.isAborted = isAborted;
  }
}
