import Component from '@glimmer/component';
import { action } from '@ember/object';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { inject as service } from '@ember/service';

export default class MwPageBackground extends Component {
  @service dynamicDialogs;

  get isOpenPage() {
    return this.args.isOpenPage;
  }

  get page() {
    return this.args.page;
  }

  get photoUrl() {
    return this.page.cover.replace('{imageSize}', '1600x1600');
  }

  @action
  coverClick() {
    if (this.isOpenPage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.photoUrl) {
      this.dynamicDialogs.openDialog('media-dialog', {
        photoUrl: this.photoUrl.replace('{imageSize}', '1600x1600'),
        mediaType: 'photoUrl',
        allowMultipleInstances: true,
      });
    }
  }
}
