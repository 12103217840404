import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { addObserver, removeObserver } from '@ember/object/observers';

import { isEmpty } from 'lodash';

import GroupApi from 'mewe/api/group-api';
import FunctionalUtils from 'mewe/shared/functional-utils';
import GroupStore from 'mewe/stores/group-store';
import { next } from '@ember/runloop';

export default class MwGroupSettingsNotifications extends Component {
  @reads('args.group') group;
  @reads('args.settings') settings;

  @tracked loaded = false;
  @tracked isSaveVisible = false;
  @tracked isSaving = false;

  @tracked showBirthDay;
  @tracked showAlerts;
  @tracked showPosts;
  @tracked followPosts;

  constructor() {
    super(...arguments);

    if (this.group && !this.group.isFetching) {
      this.groupDataReady();
    }

    addObserver(this, 'group.isFetching', this, this.groupDataReady);
    addObserver(this, 'settings', this, this.groupDataReady);
  }

  willDestroy() {
    removeObserver(this, 'group.isFetching', this, this.groupDataReady);
    removeObserver(this, 'settings', this, this.groupDataReady);
  }

  groupDataReady() {
    if (this.settings && !this.loaded && this.group?.id && !this.group.isFetching) {
      /**
       * each setting is stored in separate field
       * so it can be compared with fetched value later
       */
      this.showBirthDay = this.settings.showBirthDay;
      this.showAlerts = !this.settings.muted;
      this.showPosts = !this.settings.groupMuted;
      this.followPosts = this.settings.followPosts;
      next(() => {
        this.loaded = true;
      });
    }
  }

  @action
  settingsChanged(name, value) {
    this[name] = value;

    if (!this.isSaving && this.loaded) {
      if (!isEmpty(this.getParams())) {
        this.saveSettings();
      }
    }
  }

  getParams() {
    const params = {};

    if (this.showBirthDay !== this.settings.showBirthDay) params.showBirthDay = this.showBirthDay;
    if (this.showAlerts !== !this.settings.muted) params.muted = !this.showAlerts;
    if (this.followPosts !== this.settings.followPosts) params.followPosts = this.followPosts;
    if (!this.showPosts !== !!this.settings.groupMuted) params.groupMuted = !this.showPosts; // settings.groupMuted is undefined when not true, so used !! for comparassion

    return params;
  }

  @action
  saveSettings() {
    const params = this.getParams();

    this.isSaving = true;

    GroupApi.putSettings(this.group.id, params)
      .then(() => {
        FunctionalUtils.info(__('Group Settings have been successfully saved.'));

        for (let p in params) {
          if (params.hasOwnProperty(p)) {
            this.settings.set(`${p}`, params[p]);

            let group = GroupStore.getState({ id: this.group.id });

            if (group) {
              if (p === 'muted') group.set('isMuted', params.muted);
              if (p === 'followPosts') group.set('followPosts', params.followPosts);
              if (p === 'groupMuted') group.set('groupMuted', params.groupMuted);
            }
          }
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }
}
