import { getOwner } from '@ember/application';
import { A } from '@ember/array';
import Service from '@ember/service';
import StickersPicker from 'mewe/pods/components/pickers/stickers-picker';
/**
 * this service make sure that registered pickers are open one at a time
 *
 * you put "register" in init()
 * "unregister" in willDestroy() - only if picker isn't singleton
 * "open" in "open" or similar callback
 */

export default class PickersService extends Service {
  _registered = A();

  register({ name, close }) {
    this._registered.pushObject({ name, close });
  }

  // if picker is singleton, no need to unregister
  unregister({ name }) {
    const found = this._registered.find((el) => el.name === name);
    if (found) this._registered.removeObject(found);
  }

  open({ name }) {
    this._registered
      .filter((el) => el.name !== name)
      .forEach((el) => {
        el.close();
      });
  }

  stickPickerIsOpen(ctx) {
    return StickersPicker(getOwner(ctx)).isOpen();
  }

  openStickerPicker(ctx) {
    if (StickersPicker(getOwner(ctx)).isOpen()) {
      StickersPicker().send('close');
      StickersPicker().set('button', null);
    } else {
      /**
       * we need to have fixed position when sticker picker is opened
       * from small or expanded mini chat - SG-26242 SG-26620
       */
      const isFixedPosition = ctx.chatSizeType === 'smallChat' || ctx.chatSizeType === 'expandedChat';

      StickersPicker(getOwner(ctx));
      // TODO: remove this and aiStickerEnabled property in the template once AI Stickers are fully launched
      StickersPicker().set('aiStickerEnabled', ctx.aiStickerEnabled);
      if (!ctx.aiStickerEnabled) {
        // TODO: remove this once AI Stickers are fully launched, we need to set the default pack
        StickersPicker().set('selectedId', 'holiday23_free');
      }
      StickersPicker().send('reposition', event.target);
      StickersPicker().set('button', event.target);

      StickersPicker().send('open', { isFixedPosition: isFixedPosition });
      StickersPicker().send('setCallback', function (sticker) {
        const actionsContext = ctx.actions.setSticker ? ctx.actions : ctx;
        actionsContext.setSticker(sticker, ctx);
      });
    }
  }
}
